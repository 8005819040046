import React, { useContext, useState, useEffect } from "react";
import { Drawer, ANCHOR, SIZE } from "baseui/drawer";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useQuery } from "react-query";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import CustomerSearch from "./CustomerSearch/CustomerSearch";
import InvoiceSearch from "./InvoiceSearch/InvoiceSearch";
import { globalsearch } from "../../services/customerSummary";

function GlobalSearch() {
  const [activeKey, setActiveKey] = useState(0);
  const [searchParams] = useSearchParams();

  let { isGlobalSearch, setGlobalFilter, currentOrganization } =
    useContext(MaxyfiContext);

  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const debounceFilter = useDebouncedCallback((filter) => {
    setDebouncedValue(filter);
  }, 1000);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GLOBAL_SEARCH_CUSTOMER_${activeKey}_${currentOrganization}`,
      {
        value: debouncedValue,
      },
    ],
    async ({ queryKey }) => {
      let { value } = queryKey[1];
      return debouncedValue.length >= 1
        ? await globalsearch({
            organization: currentOrganization,
            pageSize: 0,
            entity: activeKey == 0 ? "customer" : "invoices",
            filters: {
              search_value: value,
            },
          })
        : null;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const q = searchParams.get("q");
    if (q && q.length > 0) {
      setSearchValue(q);
      debounceFilter(q);
    }
  }, []);

  return (
    <Drawer
      isOpen={isGlobalSearch}
      autoFocus
      onClose={() => {
        setGlobalFilter();
      }}
      size={SIZE.auto}
      anchor={ANCHOR.left}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            transform: " translateX(58px)",
            zIndex: 2,
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            width: "100%",
            marginRight: "0px",
            marginTop: "0px",
            marginBottom: "0px",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            width: "32%",

            margin: "0px",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
      }}
    >
      <div style={{ height: "100%", overflow: "hidden" }}>
        <Tabs
          fill={FILL.fixed}
          overrides={{
            Root: {
              style: ({ $isActive }) => ({
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }),
            },
            TabBorder: {
              style: ({ $isActive }) => ({
                height: "2px",
              }),
            },
            TabList: {
              style: ({ $theme }) => ({
                height: "50px",
              }),
            },
            TabHighlight: {
              style: ({}) => ({
                borderRadius: "3px",
                bottom: "2px",
                height: "2px",
                backgroundColor: "#516BEB",
              }),
            },
          }}
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
            setSearchValue("");
            debounceFilter("");
          }}
          activateOnFocus
        >
          <Tab
            title="Consumer Search"
            overrides={{
              Root: {
                style: () => ({}),
              },
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  overflow: "hidden",
                }),
              },
            }}
          >
            <CustomerSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              debounceFilter={debounceFilter}
              isLoading={isLoading}
              data={data && data.data && data.data.docs ? data.data.docs : []}
            />
          </Tab>
          <Tab
            title="Invoice Search"
            overrides={{
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  overflow: "hidden",
                }),
              },
            }}
          >
            <InvoiceSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              debounceFilter={debounceFilter}
              isLoading={isLoading}
              data={data && data.data && data.data.docs ? data.data.docs : []}
            />
          </Tab>
        </Tabs>
      </div>
    </Drawer>
  );
}

export default GlobalSearch;
