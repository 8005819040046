import { Controller, useForm, useWatch } from "react-hook-form";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import React, { useContext, useMemo, useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { SelectBox } from "../../components/SelectBox";
import { TextButton, SIZE, KIND } from "../../components/TextButton";
import { useIntl } from "react-intl";
import { TextArea, SIZE as TASize } from "../../components/TextArea";
import { ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import {
  addRequestInformation,
  updateRequestInformation,
  customerInvoiceOverview,
  postDisputeData,
  putDisputeData,
  getCustomerDetails,
  getHolidayBlackoutHolidayDate,
} from "../../services";
import { getSubCategory } from "../../services/documentSystem";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import {
  requestInformationAttachment,
  requestInformationRemoveAttachment,
  requestInformationUpdateAttachment,
  requestInformationReset,
} from "../../redux/actions";
import { Plus } from "baseui/icon";
import EmailAttachment from "../../components/EmailAttachment/EmailAttachment";
import {
  ADD_LIST_VALUES,
  ASSIGN_RI_TO_CLIENT,
  CLIENT_PORTAL_ACCESS,
} from "../../providers/RBACProvider/permissionList";
import "quill-mention";
import _ from "lodash";
import "quill-mention/dist/quill.mention.css";
import { FormattedMessage } from "react-intl";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { CO_INVOICES_, CUSTOMER_OVERVIEW_ } from "../../constants";
import queryClient from "../../providers/queryClient";
import useFormat from "../../hooks/useFormat";
import Loader from "../../components/Loader";
import { useDebouncedCallback } from "use-debounce";
import { Icon, Typography } from "../../components_v2";
import { TextBox } from "../../components/TextBox";
import { LABEL_PLACEMENT } from "baseui/checkbox";

function DisputeForm({
  isEdit = false,
  defaultValues = {},
  resourceId,
  sub_category = "",
  customerId,
  disputeId,
  active,
  ...props
}) {
  const dispatch = useDispatch();
  const [text, setAlert] = useState(false);
  const [isInvoice, setIsInvoice] = useState([]);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const attachments = useSelector((s) => s.requestInformation.attachments);
  const [isChangeInvoices, setIsChangeInvoices] = useState([]);
  const customer = useSelector(
    (s) => s.customerOverviewReducer.customerDetails
  );

  let {
    currentOrganization,
    users,
    referenceData,
    currentDefaultFormatDetails,
    userInfo,
    space,
    myTeam,
  } = useContext(MaxyfiContext);
  const editorRef = useRef(null);

  const isClientAccess =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  const riqPermission =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(ASSIGN_RI_TO_CLIENT);

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return (
        e?.organization?.includes(currentOrganization) &&
        e.active &&
        !(
          e.is_client_login == true &&
          !e.business_unit
            ?.map((bt) => bt.business_unit_id)
            ?.includes(props.business_unit)
        )
      );
    });
    return orgBusiness || [];
  }, [props.business_unit]);

  const filterRiqUser = orgUsers?.filter(
    (e) => e && e.is_client_login !== true
  );

  useEffect(() => {
    return () => {
      dispatch(requestInformationReset());
    };
  }, []);

  useEffect(() => {
    setValue(
      "amt",
      isChangeInvoices.length > 0
        ? isChangeInvoices
            .reduce((sum, item) => +sum + +item?.outstanding?.value, 0)
            .toFixed(2)
        : _.get(defaultValues, "amt", "")
    );
  }, [isChangeInvoices]);

  const customerData = useQuery(
    [`${CUSTOMER_OVERVIEW_}${customerId}`],
    async () => {
      return await getCustomerDetails({
        organization: currentOrganization,
        customerId,
      });
    },
    {
      // Optional configuration options for useQuery
      enabled: !queryClient.getQueryData(`${CUSTOMER_OVERVIEW_}${customerId}`), // Check if data exists for the key
      staleTime: 0, // Fetch every time (optional)
    }
  );

  const businessUnit = referenceData["business_unit_list"]
    ? referenceData["business_unit_list"].find(
        (e) =>
          e.id ===
          (isEdit
            ? props?.business_unit
            : _.get(customerData, "data.data.doc.business_unit"))
      )
    : null;

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(isEdit
        ? { ...defaultValues }
        : {
            status: [{ id: "OPEN" }],
          }),
    },
  });

  let { review_date } = watch();

  const blackoutHolidayData = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: review_date
            ? [
                moment(review_date)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: review_date ? true : false,
    }
  );

  const intl = useIntl();
  const [queryFilter, setQueryFilter] = useState({});
  const debounceFilter = useDebouncedCallback((filter) => {
    setQueryFilter(filter);
  }, 1000);
  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
      },
      validator: (file) => {
        if (file && file.size > 10000000) {
          return {
            code: "File is too-large",
            message: "File Size is Largen than 1MB",
          };
        }
      },
      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });

        dispatch(requestInformationAttachment(arrObj));
      },
    });

  const { data, isLoading } = useQuery(
    [
      `${CO_INVOICES_}${customerId}`,
      {
        filters: queryFilter,
        // pgid: _.get(customerData, "data.doc.pgid", ""),
        gid: _.get(customerData, "data.data.doc.gid", ""),
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, gid } = queryKey[1];
      filters = {
        ...filters,
        ...(queryFilter?.status?.length > 0 &&
        _.get(customerData, "data.data.doc.pgid")
          ? { pgid: _.get(customerData, "data.data.doc.pgid", "") }
          : queryFilter?.status?.length > 0 &&
            !customerData?.data?.data?.doc?.pgid &&
            customerData?.data?.data?.doc?._id
          ? { customer_id: customerData?.data?.data?.doc?._id }
          : gid && gid.length > 0
          ? { gid }
          : {}),
        // ...( : {}),
        // ...appAccessRest,
      };

      return await customerInvoiceOverview({
        organization: currentOrganization,
        customer_id: customerId,
        pageIndex: 0,
        pageSize: 0,
        sortBy: "-_id",
        filters, //TODO
      });
    },
    {
      enabled: !queryClient.getQueryData([
        `${CO_INVOICES_}${customerId}`,
        { filters: {}, gid: _.get(customerData, "data.data.doc.gid", "") },
      ]),
      staleTime: 0,
    }
  );

  const format = useFormat();

  const invOption = useMemo(() => {
    if (!customerData || customerData.isLoading || !data || !data.data) {
      return [];
    }

    const customerDataDoc = _.get(customerData, "data.data.doc", {});
    const customerId = customerDataDoc?.pgid || customerDataDoc?._id || "";

    let findpgid = [];

    if (customerId) {
      findpgid = data.data.docs.filter(
        ({ pgid, customer_id }) =>
          pgid === customerId || customer_id === customerId
      );
    }

    const invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(
        ({
          _id,
          not_paid_amount,
          business_unit,
          invoice_number,
          client_reference,
          invoice_date,
        }) => ({
          id: _id,
          outstanding: not_paid_amount,
          label: `${format.rd({
            id: business_unit,
            name: "business_unit_list",
          })}${business_unit ? " / " : ""}
                ${invoice_number}${invoice_number ? " / " : ""}
                ${client_reference}${client_reference ? " / " : ""}
                ${moment
                  .utc(invoice_date)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .format(currentDefaultFormatDetails.date_format)}
                ${not_paid_amount.currency ? " / " : ""}${
            not_paid_amount.currency
          }${not_paid_amount.value ? " " : ""}${not_paid_amount.value}`,
        })
      );

    setCurrentInvoice([...invIds]);

    if (props?.disputeData?.invoices && Array.isArray(invIds) && invIds) {
      let foundValue = invIds.filter((item) =>
        props?.disputeData?.invoices.includes(item.id)
      );
      setValue("invoices", foundValue, true);
    }

    return invIds;
  }, [isLoading, customerId]);
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  const addRequestInformationMutation = useMutation(
    (data) =>
      postDisputeData({
        data,
        organization: currentOrganization,
        customerId: customerId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        queryClient.invalidateQueries(`customer-${customerId}-dispute`);

        props.reset();
      },
    }
  );

  const updateRequestInformationMutation = useMutation(
    (data) =>
      putDisputeData({
        data,
        organization: currentOrganization,
        customerId: customerId,
        disputeId: disputeId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        // reset();
        setValue("comment", "");
        dispatch(requestInformationReset());
        queryClient.invalidateQueries(`customer-${customerId}-dispute`);
        queryClient.invalidateQueries(
          `customer-${customerId}-dispute-${resourceId}`
        );
      },
    }
  );

  const onSubmit = async (data) => {
    let {
      details = "",
      type = [],
      invoices = [],
      assigned_to = [],
      review_date,
      amt,
      status = [],
      comment = "",
      is_hold_workflow = false,
    } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      details,
      type: getSelectValues(type),
      ...(amt ? { amt: Number(amt) } : {}),
      ...(!isEdit
        ? {
            is_hold_workflow,
          }
        : {
            attachments:
              attachments && Array.isArray(attachments)
                ? attachments.filter((e) => e.fileRef).map((e) => e.fileRef)
                : [],
          }),

      // ...(!isEdit
      //   ? {
      //       category,
      //       details,
      //       sub_category: getSelectValues(sub_category, true),
      //     }
      //   : {
      //       status: getSelectValues(status),
      //       mention_users: mentionUsers,
      //       attachments:
      //         attachments && Array.isArray(attachments)
      //           ? attachments.filter((e) => e.fileRef).map((e) => e.fileRef)
      //           : [],
      //       comment,
      //     }),

      // ...(isEdit && !isClientAccess ? { details } : {}),

      mention_users: uniqueMentionedUsers,
      invoices: getSelectValues(invoices, true),
      assigned_to: getSelectValues(assigned_to),
      ...(assigned_to && assigned_to[0] && assigned_to[0].isClient
        ? { assign_type: "BUSINESS_UNIT" }
        : { assign_type: "USER" }),
      ...(review_date
        ? {
            review_date: moment
              .tz(
                `${review_date.getFullYear()}-${
                  review_date.getMonth() + 1
                }-${review_date.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      status: getSelectValues(status),

      comment,
    };

    if (!isEdit) {
      await addRequestInformationMutation.mutateAsync(values);
    } else {
      await updateRequestInformationMutation.mutateAsync(values);
    }
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  if (customerData?.isLoading) {
    return <Loader></Loader>;
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        marginTop: isEdit ? "10px" : "",
        marginBottom: isEdit ? "10px" : "",
      }}
    >
      {!isEdit || (isEdit && !isClientAccess) ? (
        <Controller
          name="details"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              fullWidth
              requiredAstric={true}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "capture_details",
              })}
              placeholder={intl.formatMessage({
                id: "capture_details",
              })}
              value={field.value}
              size={TASize.mini}
            />
          )}
        />
      ) : (
        <></>
      )}
      {isEdit ? (
        <>
          <div style={{ paddingBottom: "5px" }}>
            <TextButton
              kind="tertiary"
              size="mini"
              type="button"
              onClick={() => {
                open();
              }}
            >
              <Plus size={18} />
              Add Document
            </TextButton>
            {attachments && Array.isArray(attachments) ? (
              attachments.map((e) => (
                <EmailAttachment
                  {...e}
                  currentOrganization={currentOrganization}
                  id={customerId}
                  subCategory={sub_category}
                  removeAttchFile={requestInformationRemoveAttachment}
                  updatingDeskUpload={requestInformationUpdateAttachment}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="row gap-10">
        {!isEdit ? (
          <Controller
            name="type"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                requiredAstric={true}
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: `dispute_type`,
                })}
                placeholder={intl.formatMessage({
                  id: "dispute_type",
                })}
                error={errors[field.name] && errors[field.name].message}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["dispute_type"] || []}
                creatable={creatablePermission ? true : false}
              />
            )}
          />
        ) : (
          <></>
        )}
        <Controller
          name="invoices"
          control={control}
          render={({ field }) => (
            <SelectBox
              name={field.name}
              label={intl.formatMessage({
                id: "invoices",
              })}
              placeholder={intl.formatMessage({
                id: "invoices",
              })}
              error={errors[field.name] && errors[field.name].message}
              size={SIZE.mini}
              value={field.value}
              multi
              onChange={(e) => {
                field.onChange(e.value);
                setIsChangeInvoices(e.value);
                setAlert(e.value.length < currentInvoice.length);
              }}
              getValueLabel={({ option }) => {
                const { label } = option;
                const value = label ? label.split("/") : [];
                return <>{value && value[1] ? value[1] : value[0]}</>;
              }}
              options={invOption}
            />
          )}
        />

        <Controller
          name="amt"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <>
              <TextBox
                {...field}
                size={SIZE.mini}
                //   disabled={isDisable}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "dispute_amount",
                })}
                placeholder={intl.formatMessage({
                  id: "dispute_amount",
                })}
                type="number"
                value={field.value}
              />
            </>
          )}
        />
      </div>
      <div className="row gap-10">
        <Controller
          name="assigned_to"
          control={control}
          render={({ field }) => (
            <SelectBox
              {...field}
              clearable={false}
              name={field.name}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: `assign_to`,
              })}
              placeholder={intl.formatMessage({
                id: "assign_to",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                orgUsers && Array.isArray(orgUsers) && riqPermission
                  ? [
                      ...orgUsers,
                      ...(businessUnit && businessUnit.id
                        ? [
                            {
                              id: businessUnit.id,
                              label: businessUnit.label,
                              isClient: true,
                            },
                          ]
                        : []),
                    ]
                  : filterRiqUser && !riqPermission
                  ? [...filterRiqUser]
                  : []
              }
            />
          )}
        />
        <Controller
          name="review_date"
          control={control}
          render={({ field }) => (
            <DateRangeSelect
              clearable={false}
              size={SIZE.mini}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "review_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <SelectBox
              {...field}
              clearable={false}
              name={field.name}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: `status`,
              })}
              placeholder={intl.formatMessage({
                id: "status",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                referenceData &&
                referenceData["request_information_status"] &&
                Array.isArray(referenceData["request_information_status"])
                  ? referenceData["request_information_status"].filter((e) => {
                      if (isClientAccess && ["OPEN", "CLOSED"].includes(e.id)) {
                        return false;
                      }

                      return true;
                    })
                  : []
              }
            />
          )}
        />
      </div>
      {/* {isEdit && ( */}
      <>
        {_.get(blackoutHolidayData, "data.data.doc.is_holiday") && (
          <>
            <div className="alert-holiday-section">
              <Icon icon="alert_circle" color="#516BEB" />
              <Typography
                type="p"
                style={{
                  fontWeight: "400",
                }}
              >
                The Date Selected{" "}
                {_.get(blackoutHolidayData, "data.data.doc.holidays", []).map(
                  (val) => {
                    return (
                      <>
                        {moment(val).format(
                          currentDefaultFormatDetails.date_format
                        )}
                      </>
                    );
                  }
                )}{" "}
                is a <b>“Holiday”</b>
              </Typography>
            </div>
          </>
        )}
      </>
      {/* )} */}
      {!isEdit ? (
        <div style={{ marginBottom: "10px" }}>
          <Controller
            defaultValues={false}
            name="is_hold_workflow"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement="right"
              >
                <FormattedMessage id={`hold_workflow_till_RECORD_DISPUTE`}>
                  hold_workflow_till_promise_date
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
      ) : (
        <></>
      )}

      <div className="rq-quill" style={{ marginBottom: "10px" }}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              name={field.name}
              id={"editor"}
              ref={editorRef}
              theme="snow"
              placeholder="comments"
              value={field.value}
              modules={modules}
              // modules={{}}
              style={{ height: "100px" }}
            />
          )}
        />
      </div>
      <div className="row row--end" style={{ justifyContent: "flex-end" }}>
        <TextButton
          size="mini"
          kind={KIND.tertiary}
          onClick={() => props.reset()}
          type="button"
        >
          Cancel
        </TextButton>
        <TextButton size="mini" kind={KIND.primary} isLoading={isSubmitting}>
          Save
        </TextButton>
      </div>
    </form>
  );
}

export default DisputeForm;
