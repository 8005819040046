import React, { useState, forwardRef } from "react";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";

export const TextBox = forwardRef((props, ref) => {
  const {
    error = "",
    children,
    value = "",
    label = "",
    requiredAstric = false,
    isEyeToggle = false,
    startEnhancer = "",
    endEnhancer = "",
    modifiLabel = "",
  } = props;
  //
  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <FormControl
      error={error}
      caption={props.caption}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "8px",
            textAlign: "left",
            color: error ? "#FD372A" : "#adadad",
          }),
        },
      }}
    >
      <div className="mx-input">
        <label
          htmlFor={props.name}
          className={`mx-input__label ${modifiLabel && modifiLabel} ${
            isFocused ? "mx-input__label--active text-primary" : ""
          } ${
            !isFocused && value && value.toString().length > 0
              ? "mx-input__label--active"
              : ""
          } ${error ? "text-error" : ""}`}
        >
          <sapn>
            {label}{" "}
            {requiredAstric ? (
              <>
                <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              <></>
            )}{" "}
          </sapn>
          {/* {label} */}
        </label>
        <Input
          {...props}
          id={props.name}
          startEnhancer={() =>
            startEnhancer ? <span>{startEnhancer}</span> : <></>
          }
          endEnhancer={() => (endEnhancer ? <span>{endEnhancer}</span> : <></>)}
          inputRef={ref}
          autoComplete={false}
          autoFill={false}
          overrides={{
            MaskToggleButton: isEyeToggle
              ? {
                  style: () => ({
                    display: "none",
                  }),
                }
              : {
                  style: () => ({
                    // backgroundColor: "#fff",
                    background: "#ffffff",
                    cursor: "pointer",
                  }),
                },
            Root: {
              style: ({ $theme, $isFocused, $error }) => ({
                borderRadius: "5px",
                borderWidth: "1px",
                width: props.width,
                borderColor: $isFocused
                  ? $error
                    ? "#FD372A"
                    : "#516BEB"
                  : $error
                  ? "#FD372A"
                  : "#CDCED9",
                backgroundColor: "#ffffff",
                paddingRight: "0px",
                padding: "0px",
                paddingLeft: "0px",
              }),
            },
            ClearIconContainer: {
              style: ({ $theme }) => ({
                backgroundColor: "#FFFFFF",
              }),
            },
            Input: {
              props: {
                id: props.name,
              },
              style: ({ $theme, $isFocused }) => ({
                backgroundColor: "#ffffff",
                "::placeholder": {
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                },
              }),
            },

            EndEnhancer: {
              style: ({ $theme }) => ({
                paddingTop: "5px",
                backgroundColor:
                  endEnhancer && endEnhancer.length ? "#EEEEEE" : "#ffffff",
                display: endEnhancer ? "flex" : "none",
                width: endEnhancer && endEnhancer.length ? "75px" : "",
              }),
            },
            StartEnhancer: {
              style: ({ $theme }) => ({
                // outline: `${$theme.colors.warning200} solid`,
                backgroundColor: "#EEEEEE",
                display: startEnhancer ? "flex" : "none",
              }),
            },
            ...props.overrides,
          }}
          value={value}
          onFocus={(e) => {
            if (props && props.type === "number") {
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              );
            }
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        >
          {children}
        </Input>
      </div>
    </FormControl>
  );
});

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
      lineHeight: "0px",
    }),
  },
  Input: {
    style: () => ({
      lineHeight: "0px",
    }),
  },
};
