import React, { useContext } from "react";
import BookDemo from "../components/BookDemoTopNav/BookDemo";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import SideBar from "./SideBar";

function Layout({ sideBar = true, children }) {
  const { space } = useContext(MaxyfiContext);

  return (
    <>
      {space.app_message && <BookDemo />}

      <div
        className={`layout-container ${
          space.app_message && "--layout_modifier"
        }`}
      >
        <div style={{ display: "flex", flex: "1" }}>
          {sideBar && <SideBar />} {children}
        </div>
      </div>
    </>
  );
}

export default Layout;
