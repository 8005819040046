import React, { useContext, useState, useEffect } from "react";
import { TextBox, SIZE as sizeBox } from "../../TextBox";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphMedium, LabelMedium } from "baseui/typography";
import SearchIcon from "../../svg/SearchIcon";
import { NumericFormat } from "react-number-format";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Skeleton } from "baseui/skeleton";
import { useDispatch } from "react-redux";
import {
  minimizeAllAction,
  minimizeSMSActionData,
} from "../../../redux/actions";

import { Typography } from "../../../components_v2";
import { useQuery } from "react-query";
import { getRecentVisitCusInv } from "../../../services";
import _ from "lodash";
import useFormat from "../../../hooks/useFormat";
import formatRecentTime from "../../../utils_v2/FormatTimeInDays";
function CustomerSearch({
  searchValue,
  setSearchValue,
  debounceFilter,
  isLoading,
  data = [],
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const format = useFormat();
  const {
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
    currentOrganization,
    setGlobalFilter,
  } = useContext(MaxyfiContext);
  const categoryData = referenceData["business_unit_list"];

  const {
    isLoading: recentDataLoading,
    data: recentData,
    refetch,
    isFetching,
    isError,
  } = useQuery(
    [`recent-visit-${currentOrganization}`],
    async () => {
      return await getRecentVisitCusInv({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: "20px",
        paddingBottom: "15px",
      }}
    >
      <div style={{ padding: "0px 12px" }}>
        <TextBox
          clearable
          size={sizeBox.mini}
          value={searchValue}
          onChange={(e) => {
            let values = e.target.value;
            setSearchValue(values);
            debounceFilter(values);
          }}
          autoFocus
          // endEnhancer={<SearchIcon style={{ cursor: "pointer" }} size="18px" />}
          placeholder={intl.formatMessage({
            id: "Search by Name, Phone #, Email ID & Consumer ID",
          })}
        />
      </div>
      <div
        style={{
          height: "calc(100% - 36px)",
          overflowY: "scroll",
          paddingBottom: "0.5px",
        }}
      >
        {_.get(recentData, "data.docs", []) &&
        !searchValue &&
        _.get(recentData, "data.docs", []).length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              type="p"
              className="text-secondary"
              style={{
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Recent Visits
            </Typography>
            {_.get(recentData, "data.docs", []).length > 0 &&
              _.get(recentData, "data.docs", []).map((recentItem) => {
                return (
                  <div
                    className="recent_visit_container"
                    onClick={() => {
                      if (q && q.length > 0) {
                        navigate(`/customers/${recentItem?.res_id}?ib=true`);
                      } else {
                        navigate(`/customers/${recentItem?.res_id}`);
                      }

                      setSearchValue("");
                      setGlobalFilter();
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        type="h4"
                        style={{
                          color: "#333860",
                          maxWidth: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {_.get(recentItem, "name", "-")}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <Typography
                          type="p"
                          className="text-secondary"
                          subType="regular"
                        >
                          {format.rd({
                            id: recentItem && recentItem?.bu,
                            name: "business_unit_list",
                          })}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",

                            gap: "3px",
                          }}
                        >
                          <Typography
                            type="p"
                            className="text-secondary"
                            subType="regular"
                          >
                            {" "}
                            {formatRecentTime(_.get(recentItem, "on", null))}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : isLoading ? (
          <>
            <div
              className={`cp__globalsearch `}
              style={{ padding: "0px 20px" }}
            >
              <LabelLarge $style={{ color: "#333860" }}>
                <Skeleton width="20%" height="16px" animation />
              </LabelLarge>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  gap: "3px",
                }}
              >
                <div className="dashboard__currency__wraper">
                  <div
                    style={{
                      height: "22px",
                      width: "55px",
                    }}
                  >
                    <Skeleton width="100%" height="16px" animation />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <div
                  style={{
                    height: "22px",
                    width: "55px",
                  }}
                >
                  <Skeleton width="100%" height="16px" animation />
                </div>
                <div
                  style={{
                    background: "#D9D9D9",
                    height: "5px",
                    width: "5px",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    height: "22px",
                    width: "55px",
                  }}
                >
                  <Skeleton width="100%" height="16px" animation />
                </div>
              </div>
            </div>
          </>
        ) : data && data.length == 0 ? (
          <p
            style={{
              paddingTop: "10px",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            No Records Found
          </p>
        ) : (
          data &&
          Array.isArray(data) &&
          data.map((e) => {
            const categoryFilter = e.business_unit
              ? categoryData.find(({ id }) => id === e.business_unit)
              : [];

            return (
              <div
                className={`cp__globalsearch ${
                  e._id === 0 ? "custmer__active" : " "
                }`}
                onClick={() => {
                  if (q && q.length > 0) {
                    navigate(
                      `/customers/${e?.pgmc ? e?.pgmc : e?._id}?ib=true`
                    );
                  } else {
                    navigate(`/customers/${e?.pgmc ? e?.pgmc : e?._id}`);
                    dispatch(minimizeAllAction());
                  }
                  setSearchValue("");
                  setGlobalFilter();
                }}
                style={{ padding: "6px 20px", height: "77px" }}
              >
                <LabelMedium
                  $style={{
                    color: "#333860",
                    maxWidth: "297px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {e.display_name}
                </LabelMedium>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    gap: "3px",
                  }}
                >
                  <div className="dashboard__currency__wraper">
                    <NumericFormat
                      displayType="text"
                      value={
                        e && e?.overdue_inv_amount && e.overdue_inv_amount.value
                          ? Number(e.overdue_inv_amount.value)
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => (
                        <LabelMedium $style={{ color: "#333860" }}>
                          <sup style={{ fontSize: "9px" }}>
                            {e.overdue_inv_amount?.currency}
                          </sup>{" "}
                          {value}
                        </LabelMedium>
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <ParagraphMedium $style={{ color: "#787878" }}>
                    {e.total_inv_count} invoices
                  </ParagraphMedium>
                  <div
                    style={{
                      background: "#D9D9D9",
                      height: "5px",
                      width: "5px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <ParagraphMedium
                    $style={{
                      color: "#787878",
                      maxWidth: "190px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {categoryFilter?.label}
                  </ParagraphMedium>
                </div>
              </div>
            );
          })
        )}
      </div>
      {/* */}
    </div>
  );
}

export default CustomerSearch;
