import { useSelector, useDispatch } from "react-redux";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import { Label2, LabelLarge } from "baseui/typography";
import Descending from "../../assets/img/svg/Descending";
// import AddPayment from "./AddPayment";
import { TextBox } from "../../components/TextBox";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Search } from "baseui/icon";
import { useIntl } from "react-intl";
import AllFilters from "../../components/AllFilters/AllFilters";
// PaginationWraper
import PaginationWraper from "../../components/Pagination/Pagination";
import TableViewPicker from "../../components/TableViewPicker";
// Redux
import {
  setInvoiceSummaryGridStructure,
  updateTableInvoiceSummaryDraftState,
  setInvoiceSummaryTableView,
  setInvoiceSummaryGridAndView,
  setInvoiceSummarySort,
  setInvoiceSummaryPageIndex,
  setInvoiceSummaryPageSize,
  setInvoiceFilter,
  setInvoiceSummaryFilter,
  setInvoiceGlobalFilter,
  setNeedsActionFilterInv,
  setTableWidthInv,
  setInvoicePinnedView,
} from "../../redux/invoiceSummary/action";
// Get Grid Structure
import { getGridStructure, getView } from "../../services";
// Constants
import { INVOICE_SUMMARY } from "../../constants";
//
import { invoiceSummaryData } from "../../services/invoiceSummary";

// Query
import { useQuery } from "react-query";

import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragDrop from "../../assets/img/svg/DragDrop";
import DragDropHorizontal from "../../assets/img/svg/DragDropHorizontal";
import SortUp from "../../assets/img/svg/SortUp";
import SortDown from "../../assets/img/svg/SortDown";
import CardMerge from "../../components/Card";
import { CUSTOMER_OVERVIEW } from "../../constants";
import CustomerAction from "../CustomerSummaryNew";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDebouncedCallback } from "use-debounce";
import Loader from "../../components/Loader";
import setTableGridStructure from "../../utils/setTableGridStructure";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  BULK_PAUSE,
  EDIT_INVOICE,
  EDIT_RECURRING_INVOICE,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import ColumnToogle from "../../components/ColumnToggle/ColumnToggle";
import { ModalContext } from "../../providers/ModalProvider";
import PauseActionForm from "../../components/FormModal/PauseActionForm";
import relManagerPic from "../../assets/img/rel-manager.png";
import PauseActionFormInv from "../../components/FormModal/PauseActionFormInv";

const TableBody = ({
  page,
  prepareRow,
  renderRowSubComponent,
  getTableBodyProps,
  visibleColumns,
}) => {
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  return (
    <div className="customer__tabel__body" {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);
        const rowProps = row.getRowProps();
        return (
          <React.Fragment key={rowProps.key}>
            <div
              key={index}
              className={`table-main__body-row --inv_summery_tabel_body ${
                row.isExpanded ? "expanded" : ""
              }`}
              {...row.getRowProps()}
            >
              {/* <Link to={`/customer/${index}`}> */}
              {row.cells.map((cell) => {
                return (
                  <div
                    className="table-main__body-cell"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
              {/* </Link> */}
              {row?.original?.is_system_generated && (
                // <div className="inv_edit_btn_Wraper">
                //   <IconButton kind={KIND.tertiary}>
                //     <i className="mx-icon-Vector-7 font_size_icon color_gr" />
                //   </IconButton>
                // </div>
                <RBACWrapper role={EDIT_INVOICE} type="PERMISSION">
                  <div className="inv_edit_btn_Wraper">
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => {
                        setAddSearchParams({
                          // customer: `${getParams}`,
                          // invoice: `recurring-form`,
                          invoice: `invoice-form`,
                          inv: `${row && row.original && row.original._id}`,
                          type: "INV-EDIT-INV",
                        });
                      }}
                    >
                      <i className="mx-icon-Vector-7 font_size_icon color_gr" />
                    </IconButton>
                  </div>
                </RBACWrapper>
              )}
            </div>
            {row.isExpanded &&
              renderRowSubComponent({
                row,
                rowProps,
                visibleColumns,
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const InvoiceSummaryTable = (props) => {
  let { currentOrganization, userPersonalization, customInvoice } =
    useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  let { id } = useParams();
  let intl = useIntl();

  let ref = useRef();
  const setView = (props) => dispatch(setInvoiceSummaryTableView(props));

  useEffect(() => {
    if (ref.current) {
      // setWidth(ref.current?.offsetWidth);
      dispatch(setTableWidthInv(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  // FILTERS STARTS

  // Customer Filter
  const filterReduxData = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.formFilters
  );

  // Customer Stage Filter
  const customerStageFilters = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.customer_stage
  );
  // Needs Action Filter

  const NeedsActionData = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.needs_action
  );

  // FILTERS ENDS

  // TO FIND LENGTH STARTS
  const InvoiceFilter = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.formFilters
  );

  // TO FIND LENGTH ENDS

  // GRID STRUCTURE FOR CUSTOMER

  const gridStructureValues = useSelector(
    (s) => s.invoiceSummaryReducer.gridStructure.table
  );

  // GRID STRUCTURE FOR CUSTOMER
  const expandedRowId = useRef(null);

  const debounceFilter = useDebouncedCallback((filter) => {
    props.setQueryFilter(filter);
    dispatch(setInvoiceSummaryPageIndex(0));
    dispatch(setInvoiceGlobalFilter(filter));
    // dispatch(setInvoiceGlobalFilter(filter));
  }, 1000);
  const dispatch = useDispatch();
  const invoiceSummaryReduxData = useSelector(
    (s) => s.invoiceSummaryReducer.tableState
  );
  let {
    viewName,
    gridAndViewLoaded,
    tableCurrentState,
    tableState,
    tableDraftView,
    gridStructure,
  } = useSelector((state) => state.invoiceSummaryReducer);
  const NeedsActionFilter = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.needs_action
  );
  const pinnedView = useSelector((s) => s.invoiceSummaryReducer.pinnedView);
  const [filterSearch, setFilterSearch] = useState(
    tableCurrentState.globalFilters
  );

  const columns = useMemo(() => {
    return invoiceSummaryReduxData.columns;
  }, [invoiceSummaryReduxData.columns]);

  const tableData = useMemo(
    () =>
      props.data && props.data.data && props.data.data.docs
        ? props.data.data.docs
        : [],
    [props.isFetched, props.data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    selectedFlatRows,
    setHiddenColumns,
    setAllFilters,
    setColumnOrder,
    allColumns,
    toggleAllRowsSelected,
    gotoPage,
    setPageSize,
    setSortBy,
    toggleRowExpanded,
    isAllRowsSelected,
  } = useTable(
    {
      columns,

      initialState: {
        sortBy: tableCurrentState.sort ? tableCurrentState.sort : [],
        pageIndex: tableCurrentState.pageIndex,
        pageSize: tableCurrentState.pageSize,
      },

      data: tableData,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        props.isFetched &&
        !props.isError &&
        props.data &&
        props.data.data &&
        props.data.data.docs
          ? props.data.data.totalPages
          : 0,
      // pageCount: isFetched && !isError && data.data ? data.data.totalPages : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const currentColOrder = React.useRef();
  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    const menu = "invoice_summary";

    if (!viewName && !gridAndViewLoaded) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: setInvoiceSummaryGridStructure,
        setViewAction: setInvoiceSummaryTableView,
        setGridAndViewAction: setInvoiceSummaryGridAndView,
        userPersonalization,
        dispatch,
        isSetGridAndView: true,
        customFields:
          customInvoice && Array.isArray(customInvoice) ? customInvoice : [],
      });
    }
  }, []);

  useEffect(() => {
    if (tableState.sort) {
      let isDesc = tableState.sort.startsWith("-");
      setSortBy([{ id: tableState.sort.replace("-", ""), desc: isDesc }]);
    }
    // if (tableCurrentState.sort && tableCurrentState.sort[0]) {
    //   setSortBy(tableCurrentState.sort);
    // }
  }, [tableState.sort]);

  useEffect(() => {
    if (invoiceSummaryReduxData.filters) {
      setAllFilters(invoiceSummaryReduxData.filters);
    }
  }, [invoiceSummaryReduxData.filters]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    setHiddenColumns(invoiceSummaryReduxData.hiddenColumns);
  }, [invoiceSummaryReduxData.hiddenColumns]);

  /** Update Table Structure Changes to Redux as Draft Table State **/
  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(
        updateTableInvoiceSummaryDraftState({
          table: allColumns,
          sort: sortBy,
        })
      );
    }
  }, [isColumnResizing, sortBy, filters, columns, allColumns, visibleColumns]);

  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return (
        <div className="table-main__row-expanded">
          <div colSpan={visibleColumns.length - 1}>Loading...</div>
        </div>
      );
    }

    // error handling here :)
    return (
      <>
        <div
          className="table-main__row-expanded"
          {...rowProps}
          key={`${rowProps.key}-expanded`}
        >
          <CardMerge />
        </div>
        <div className="table-main__row-expanded-bottom">
          <p>#Hospitality</p>
          <p>#foodSupplier</p>
          <p>#CriticalCustomer</p>
        </div>
      </>
    );
  }

  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(true);
    const [data] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={data}
        loading={loading}
      />
    );
  }

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  useEffect(() => {
    let currTableSort =
      sortBy && sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : sortBy[0].id
        : null;

    let currRdxSort =
      tableCurrentState.sort && tableCurrentState.sort[0]
        ? tableCurrentState.sort[0].desc
          ? "-" + tableCurrentState.sort[0].id
          : tableCurrentState.sort[0].id
        : null;

    if (currTableSort != currRdxSort) {
      gotoPage(0);
      dispatch(setInvoiceSummarySort(sortBy));
    }
  }, [sortBy]);

  const {
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let selectedCustomer = [...(selectedFlatRows ? selectedFlatRows : [])].map(
    (i) => i.original._id
  );

  return (
    <>
      <div
        // className="
        // --table__modifier"
        className="table-main"
        // style={{}}
        {...getTableProps()}
        style={{ overflowY: "hidden" }}
      >
        <div className="table-main-container" ref={ref}>
          <div className="table-main__head" style={{ position: "unset" }}>
            {headerGroups.map((headerGroup) => (
              <>
                <div className="table-main__head-container">
                  {selectedFlatRows && selectedFlatRows.length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        height: "50px",
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        padding: "0px 8px",
                      }}
                    >
                      <RBACWrapper role={BULK_PAUSE} type="PERMISSION">
                        <div
                          style={{
                            width: "120px",
                            height: "36px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              successCallback: () => {
                                // deleteTemplate.mutate(_id);
                              },
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <PauseActionFormInv
                                      selectedInvoice={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                              image: relManagerPic,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <i className="mx-icon-add" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333860",
                                fontWeight: "450",
                              }}
                            >
                              Pause Action
                            </div>
                          </div>
                        </div>
                      </RBACWrapper>
                    </div>
                  ) : (
                    <div
                      className="table-main__head-row"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          width: "250px",
                          // marginTop: "1%",
                          paddingTop: "25px",
                        }}
                      >
                        <TextBox
                          // overrides={{
                          //   StartEnhancer: {
                          //     style: ({ $theme }) => ({
                          //       display: "flex",
                          //       alignItems: "center",
                          //       justifyContent: "center",
                          //     }),
                          //   },
                          // }}

                          label={intl.formatMessage({
                            id: "search_invoice",
                          })}
                          placeholder={intl.formatMessage({
                            id: "search_invoice",
                          })}
                          endEnhancer={<Search size="18px" />}
                          onChange={(val) => {
                            let value = val.target.value;
                            setFilterSearch(value);
                            debounceFilter(value);
                          }}
                          size={SIZE.compact}
                          value={filterSearch}
                          clearable
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          gap: "10px",
                        }}
                      >
                        <div style={{}}>
                          <TableViewPicker
                            allColumns={[]}
                            tableDraftView={tableDraftView}
                            viewName={viewName}
                            menu={"invoice_summary"}
                            setView={setView}
                            currentFilteringData={filterReduxData}
                            gridStructure={gridStructure}
                            tableCurrentState={tableCurrentState}
                            pinnedView={pinnedView}
                            setPinnedView={setInvoicePinnedView}
                          />
                        </div>
                        <div style={{ width: "40px" }}>
                          <AllFilters
                            setQueryFilter={props.setQueryFilter}
                            setPageIndex={gotoPage}
                            filterReduxData={filterReduxData}
                            // stageFilter={customerStageFilters}
                            NeedsAction={NeedsActionData}
                            name={"Invoice"}
                            gridStructureValues={gridStructureValues}
                            mainFilter={(data) =>
                              dispatch(setInvoiceSummaryFilter(data))
                            }
                            needsActionFilter={setNeedsActionFilterInv}
                            pageIndex={setInvoiceSummaryPageIndex}
                            mainFilterDataLength={InvoiceFilter}
                            needsActionFilterDataLength={NeedsActionFilter}
                            id={"invoice"}
                            title={"invoice"}
                          />
                        </div>
                        <ColumnToogle
                          allColumns={allColumns}
                          setColumnOrder={setColumnOrder}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="table-main__head-row"
                    style={{ backgroundColor: "#F5F6FA" }}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <div
                        className="table-main__head-cell-wrapper"
                        {...column.getHeaderProps()}
                      >
                        <div className="table-main__head-cell">
                          {column.render("Header")}
                          {column.canResize && (
                            <a
                              className="table-main__head-cell-resizer"
                              {...column.getResizerProps()}
                            ></a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </div>
          {props.isLoading || !gridAndViewLoaded ? (
            <Loader />
          ) : tableData && tableData.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBodyMemo
              isColumnResizing={isColumnResizing}
              page={page}
              prepareRow={prepareRow}
              renderRowSubComponent={renderRowSubComponent}
              getTableBodyProps={getTableBodyProps}
              visibleColumns={visibleColumns}
            />
          )}

          {/* <CustomerAction
            className="nested-ribbon"
            selectedFlatRows={selectedFlatRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
          /> */}
        </div>
      </div>
      <PaginationWraper
        pageIndex={tableCurrentState.pageIndex}
        pageSize={tableCurrentState.pageSize}
        pageCount={pageCount}
        localKey={"invoice_summary_page_size"}
        gotoPage={(val) => {
          gotoPage(val);
          dispatch(setInvoiceSummaryPageIndex(val));
        }}
        setPageSize={(val) => {
          setPageSize(val);
          dispatch(setInvoiceSummaryPageSize(val));
        }}
      />
    </>
  );
};

export default InvoiceSummaryTable;
