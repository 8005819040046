import { combineReducers } from "redux";
import { customerSummaryReducer } from "./customerSummary/reducer";
import { fetchNestedReducer } from "./nestedSelect/reducer";
import communicationTemplateReducer from "./communicationTemplate/reducer";
// import { customerOverviewReducer } from "./customerOverview/reducer";
import { createWorkFlowReducer } from "./createWorkFlow/reducer";
import { workflowMaintenanceReducer } from "./workflow/reducer";
import { invoiceSummaryReducer } from "./invoiceSummary/reducer";
import settingReducer from "./setting/reducer";
import overViewReducer from "./customerOverview/reducer";
import customerOverviewReducer from "./customerOverview/reducer";
import { customerDairyNoteReducer } from "./customerDairyNote/reducer";
import customerPaymentReducer from "./customerOverview/payment/reducer";
import { creditNoteReducer } from "./CreditNote/reducer";
import { switchOrganizationReducer } from "./switchOrganization/reducer";
import adhocDataReducer from "./adhocUploadData/reducer";
import customerDisputeReducer from "./customerOverview/dispute/reducer";
import invoiceOverviewReducer from "./invoiceOverview/reducer";
import filterTabsReducer from "./filterTabs/reducer";
import userReducer from "./users/reducer";
import { organizationListReducer } from "./organizationList/reducer";
import customerDocumentReducer from "./customerOverview/DMSystem/reducer";
import invoiceDocumentReducer from "./invoiceOverview/DMSystemINV/reducer";
import timelineReducer from "./customerOverview/timeline/reducer";
import checkInReducer from "./customerOverview/checkIn/reducer";
import letterStatusReducer from "./customerOverview/letterStatus/reducer";
import invoiceAddingDocumentReducer from "./invoiceAdding/dmsInvoiceAdding/reducer";
import letterReducer from "./customerOverview/letter/reducer";
import { businessMaintenanceReducer } from "./businessMaintenanceUnit/reducer";
import customerAndInvoiceAdhoc from "./CustomerAndInvoiceAdhoc/reducer";
import customerContactUploadReducer from "./CustomerContactUpload/reducer";
import LetterStatesMaintenance from "./letterstatusMaintenance/reducer";
import callAction from "./customerOverview/callAction/reducer";
import adhocPaymentUpload from "./adhocPaymentUpload/reducer";
import invoiceQAReducer from "./invoiceOverview/invoiceQA/reducer";
import customerOverviewDrawer from "./customerOverviewDrawer/reducer";
import { miniMirandaReducer } from "./miniMiranda/reducer";
import requestInformationReducer from "./requestInformation/reducer";
import SMSActionReducer from "./customerOverviewDrawer/SMSAction/reducer";
import predefinedUploadReducer from "./adhocUploadData/predefindUpload/reducer";
import notification from "./notification/reducer";
import predefinedPaymentUploadReducer from "./adhocUploadData/predefindPaymentUpload/reducer";
import { checkMaintenanceReducer } from "./checkMaintenance/reducer";
import stickyStatesMaintenance from "./stickyNotesMaintainence/reducer";
import strategyMaintainence from "./strategyMaintenance/reducer";
const rootReducer = combineReducers({
  businessMaintenanceReducer: businessMaintenanceReducer,
  letterStatusReducer: letterStatusReducer,
  invoiceAddingDocumentReducer: invoiceAddingDocumentReducer,
  checkInReducer: checkInReducer,
  filterTabsReducer: filterTabsReducer,
  customerSummary: customerSummaryReducer,
  communicationTemplate: communicationTemplateReducer,
  fetchNestedReducer: fetchNestedReducer,
  createWorkFlowReducer: createWorkFlowReducer,
  invoiceSummaryReducer: invoiceSummaryReducer,
  settingReducer: settingReducer,
  customerOverviewReducer: customerOverviewReducer,
  customerDairyNoteReducer: customerDairyNoteReducer,
  customerPaymentReducer: customerPaymentReducer,
  customerDisputeReducer: customerDisputeReducer,
  creditNoteReducer: creditNoteReducer,
  switchOrganization: switchOrganizationReducer,
  adhocDataReducer: adhocDataReducer,
  userReducer: userReducer,
  invoiceOverviewReducer: invoiceOverviewReducer,
  organizationListReducer: organizationListReducer,
  customerDocumentReducer: customerDocumentReducer,
  invoiceDocumentReducer: invoiceDocumentReducer,
  timelineReducer: timelineReducer,
  letterReducer: letterReducer,
  customerAndInvoiceAdhoc: customerAndInvoiceAdhoc,
  customerContactUploadReducer: customerContactUploadReducer,
  LetterStatesMaintenance: LetterStatesMaintenance,
  callAction: callAction,
  adhocPaymentUpload: adhocPaymentUpload,
  invoiceQAReducer: invoiceQAReducer,
  customerOverviewDrawer: customerOverviewDrawer,
  miniMiranda: miniMirandaReducer,
  requestInformation: requestInformationReducer,
  SMSAction: SMSActionReducer,
  predefinedUpload: predefinedUploadReducer,
  notification,
  predefinedPaymentUpload: predefinedPaymentUploadReducer,
  checkMaintenance: checkMaintenanceReducer,
  StickyStatesMaintenance: stickyStatesMaintenance,
  strategyMaintainence: strategyMaintainence,
});

export default rootReducer;
