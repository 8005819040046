import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { Icon, Typography } from "../../components_v2";
import { TextArea } from "../../components/TextArea";
import { TextButton, KIND } from "../../components/TextButton";
import {
  updateCustomerActionWorkflowStatus,
  useCustomerUpdateAction,
} from "../../services";
import { ACTIVE } from "../../constants";
import { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import ActionReminders from "../../components_v2/ActionReminders";

const ResumeAction = ({ handleModal, customerId, entity }) => {
  const intl = useIntl();

  const { currentOrganization } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const { mutateAsync, isLoading } = useCustomerUpdateAction();

  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...data,
      mention_users: uniqueMentionedUsers,
      id: customerId,
      organization: currentOrganization,
      actionStatus: ACTIVE,
      entity: entity,
    };

    await mutateAsync(
      {
        ...values,
      },
      {
        onSuccess: (data) => {
          reset();
          handleModal();
        },
        onError: (error) => {
          if (error.response) {
            let { data } = error.response;
            let errorData = data.message;
            toast.error(errorData);
          }
        },
      }
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        textAlign: "center",
        marginTop: "15px",
        // padding: "10px 20px",
        padding: "5px 35px",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
          }}
        >
          <Icon icon="resume_action" size={18} />
          <Typography type="h2" subType="medium">
            Resume Action
          </Typography>
        </div>

        <div style={{ paddingTop: "15px" }}>
          <ActionReminders
            watch={watch}
            control={control}
            errors={errors}
            setValue={setValue}
            is_calendar={false}
            is_incrementor={false}
            is_hold={false}
            is_followup={false}
            is_confirmation={false}
            is_valid_from_contact={false}
          />
        </div>
        <div
          style={{
            paddingTop: "5px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100px" }}>
            <TextButton
              type="button"
              onClick={() => handleModal()}
              fullWidth
              kind={KIND.tertiary}
              size="mini"
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ width: "100px" }}>
            <TextButton
              type="submit"
              fullWidth
              disabled={isLoading}
              isLoading={isLoading}
              size="mini"
            >
              Save
            </TextButton>
          </div>
        </div>
      </>
    </form>
  );
};

export default ResumeAction;
