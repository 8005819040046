import React, { useState } from "react";

function ProfileIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6233 11.1231C16.5534 11.0566 16.4757 11.0048 16.3825 10.9678C16.2971 10.9308 16.1961 10.916 16.1029 10.916C16.0097 10.916 15.9087 10.9308 15.8233 10.9678C15.7379 11.0048 15.6524 11.0566 15.5825 11.1231C15.5126 11.1897 15.4583 11.2637 15.4272 11.3524C15.3961 11.4412 15.3728 11.53 15.3728 11.6187C15.3728 11.7075 15.3961 11.8037 15.435 11.885C15.4738 11.9664 15.5282 12.0478 15.5981 12.1069C16.2117 12.6839 16.701 13.3718 17.035 14.1337C17.3689 14.8956 17.5398 15.7093 17.5398 16.5378C17.5398 17.3884 14.9922 18.6163 10.9922 18.6163C7 18.6163 4.44466 17.3884 4.44466 16.5378C4.44466 15.7167 4.60777 14.9104 4.94175 14.1485C5.26796 13.394 5.75728 12.7061 6.36311 12.1291C6.43301 12.0626 6.48738 11.9886 6.52621 11.9072C6.56505 11.8259 6.58058 11.7297 6.58058 11.6409C6.58058 11.5522 6.56505 11.456 6.52621 11.3746C6.48738 11.2933 6.43301 11.2119 6.37087 11.1453C6.30097 11.0788 6.2233 11.027 6.1301 10.9974C6.04466 10.9604 5.94369 10.9456 5.85049 10.9456C5.75728 10.9456 5.65631 10.9678 5.57087 10.9974C5.48544 11.027 5.4 11.0861 5.33786 11.1527C4.59223 11.8554 4.00194 12.6987 3.59806 13.6233C3.20194 14.548 3 15.5392 3 16.5378C3 18.7865 7.12427 19.9996 11 19.9996C14.8757 19.9996 19 18.7865 19 16.5378C19 15.5318 18.7981 14.5332 18.3864 13.6011C17.9748 12.6691 17.3767 11.8259 16.6233 11.1231Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.3"
        stroke-miterlimit="10"
      />
      <path
        d="M10.9994 11.6931C12.0092 11.6931 12.9878 11.412 13.8266 10.8795C14.6655 10.3469 15.3179 9.59237 15.6985 8.70473C16.0868 7.81708 16.1878 6.84068 15.9859 5.90125C15.7839 4.96183 15.3024 4.09638 14.5956 3.41585C13.881 2.73532 12.98 2.27671 11.9859 2.09178C10.9994 1.90685 9.9742 2.00302 9.04216 2.36547C8.11012 2.73532 7.31789 3.35667 6.75867 4.14816C6.19944 4.94704 5.9043 5.87906 5.9043 6.84068C5.9043 8.12776 6.44022 9.35567 7.39556 10.2655C8.35867 11.1753 9.64799 11.6857 10.9994 11.6931ZM10.9994 3.37886C11.7218 3.37886 12.4208 3.57858 13.0189 3.96323C13.6169 4.34048 14.0829 4.88786 14.3548 5.51661C14.6266 6.15275 14.7043 6.84807 14.5645 7.51381C14.4247 8.18694 14.0752 8.80089 13.5703 9.28909C13.0655 9.7699 12.413 10.1028 11.7062 10.2359C10.9994 10.3691 10.2693 10.3025 9.60915 10.0362C8.94119 9.7773 8.3742 9.33348 7.97808 8.7639C7.58197 8.19433 7.36449 7.5286 7.36449 6.84068C7.36449 5.92344 7.75284 5.0432 8.42857 4.39226C9.11206 3.74872 10.0363 3.37886 10.9994 3.37886Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.3"
        stroke-miterlimit="10"
      />
    </svg>
  );
}

export default ProfileIcon;
