import React, { useContext, useEffect } from "react";
import { StatefulPopover } from "baseui/popover";
import { Input } from "baseui/input";
import AllFiltersIcon from "../../assets/img/svg/AllFiltersIcon";
import { IconButton, SIZE, KIND } from "../IconButton";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import CustomFilters from "./VariousTabFilters/CustomFilters";
import NeedsActionFilters from "./VariousTabFilters/NeedsActionFilters";
import CustomerStageFilters from "./VariousTabFilters/CustomerStageFilters";
import { useSelector } from "react-redux";
import Notification from "./Notification.js/Notification";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setCustomerSummaryFilter,
  setNeedsActionFilter,
  setCustomerStageFilter,
  setCustomerSummaryPageIndex,
  setNavlinkReset,
} from "../../redux/customerSummary/action";
import { TextButton } from "../TextButton";
import { setInvoiceSummaryPageSize } from "../../redux/invoiceSummary/action";
import { useIntl } from "react-intl";

const AllFilters = ({
  setPageIndex,
  setQueryFilter,
  filterReduxData,
  stageFilter,
  NeedsAction,
  name,
  gridStructureValues,
  mainFilter,
  needsActionFilter = () => {},
  customerStageFilter,
  pageIndex,
  mainFilterDataLength,
  needsActionFilterDataLength,
  customerStageFilterDataLength,
  id,
  title,
  label,
}) => {
  let { referenceData, currentOrganization } = useContext(MaxyfiContext);
  let dispatch = useDispatch();

  const intl = useIntl();

  // const orgRef = referenceData.organizations.find(
  //   ({ id }) => id === currentOrganization
  // );

  // const timeZone = orgRef.time_zone;

  // Customer Filter
  // const filterReduxData = useSelector(
  //   (s) => s.customerSummary.tableCurrentState.formFilters
  // );
  // Needs Action
  const NeedsActionFiltersRD = referenceData?.needs_action_filter;

  // Customer Stage
  const CustomerStageFilterRd = referenceData?.customer_stage_filter;

  // const customerStageFilters = useSelector(
  //   (s) => s.customerSummary.tableCurrentState.customer_stage
  // );

  const [activeKey, setActiveKey] = React.useState("0");

  const NeedsActionFilterLength = needsActionFilterDataLength
    ? Object.keys(needsActionFilterDataLength).filter(
        (i) => needsActionFilterDataLength[i]
      )
    : [];

  const CustomerStageFilterLength = customerStageFilterDataLength
    ? Object.keys(customerStageFilterDataLength).filter(
        (i) => customerStageFilterDataLength[i]
      )
    : [];

  let overAllLength =
    NeedsActionFilterLength?.length +
    CustomerStageFilterLength?.length +
    mainFilterDataLength?.rules?.length;

  //TODO: COUNT
  // (mainFilterDataLength && mainFilterDataLength.filter((e) => e.type)).length;

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      filter: {
        combinator: "and",
        rules: [],
      },
      needsAction: {
        ...NeedsActionFiltersRD?.reduce((prev, curr) => {
          prev[curr.id] = false;
          return prev;
        }, {}),
        ...NeedsAction,
      },
      stage: {
        ...CustomerStageFilterRd?.reduce((prev, curr) => {
          prev[curr.id] = false;
          return prev;
        }, {}),
        ...stageFilter,
        // ...customerStageFilters,
      },
    },
  });

  useEffect(() => {
    setValue(
      "filter",
      filterReduxData
        ? filterReduxData
        : {
            combinator: "and",
            rules: [],
          }
    );
    setValue("needsAction", NeedsAction);
    setValue("stage", stageFilter);
  }, [filterReduxData, NeedsAction, stageFilter]);

  const onSubmit = async (data) => {
    // Custom Filter
    // let filteredData = data.filter.filter((i) => i.type && i.type !== "");
    let filterData = data.filter.rules.filter((i) => i.value !== "");

    mainFilter({
      filters: data.filter,
      formFilters: data.filter,
    });

    dispatch(needsActionFilter(data.needsAction));

    if (customerStageFilter) {
      // Custom Stage Filter
      dispatch(customerStageFilter(data.stage));
    }

    // Changing Page Index
    dispatch(pageIndex(0));
    dispatch(setInvoiceSummaryPageSize(10));
    // setQueryFilter(data.filter);
    setPageIndex(0);
  };

  let { filter } = watch();

  const resetForm = () => {
    setValue("filter", {
      combinator: "and",
      rules: [],
    });
    NeedsActionFiltersRD.forEach((e) => {
      setValue(`needsAction.${e.id}`, false);
    });
    CustomerStageFilterRd.forEach((e) => {
      setValue(`stage.${e.id}`, false);
    });
    mainFilter({
      formFilters: {
        combinator: "and",
        rules: [],
      },
    });
    // dispatch(mainFilter({ formFilters: [{ id: [] }] }));
    // dispatch(setNavlinkReset());
    dispatch(needsActionFilter({}));
    dispatch(customerStageFilter({}));
    setQueryFilter({});
    setPageIndex(0);
  };

  return (
    <StatefulPopover
      content={() => (
        <form
          style={{ backgroundColor: "#FFFFFF" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Tabs
            fill={FILL.fixed}
            overrides={{
              Root: {
                style: ({ $isActive }) => ({
                  display: "flex",
                  flexDirection: "column",
                  width: `${id === "letter" ? "450px" : "600px"}`,
                }),
              },
              TabBorder: {
                style: ({ $isActive }) => ({
                  height: "2px",
                }),
              },
              TabList: {
                style: ({ $theme }) => ({}),
              },
              TabHighlight: {
                style: ({}) => ({
                  borderRadius: "3px",
                  backgroundColor: "#516BEB",
                }),
              },
            }}
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              setActiveKey(activeKey);
            }}
            // onChange={()=>{
            //   dispatch(tabMotion(0))
            // }}

            activateOnFocus
          >
            <Tab
              title={`${intl.formatMessage({
                id: title,
              })} ${
                mainFilterDataLength &&
                mainFilterDataLength.rules &&
                mainFilterDataLength.rules.length > 0
                  ? `(${mainFilterDataLength?.rules?.length})`
                  : ""
              }`}
            >
              <CustomFilters
                setQueryFilter={setQueryFilter}
                control={control}
                watch={watch}
                setValue={setValue}
                resetForm={resetForm}
                gridStructureValues={gridStructureValues}
                value={filter}
              />
            </Tab>
            {id === "user" ||
            id === "client" ||
            id === "adhoc" ||
            id === "credit" ||
            id === "letter" ||
            id === "check" ? (
              ""
            ) : (
              <Tab
                // title="Needs Action"
                title={`${intl.formatMessage({
                  id: "needs_action",
                })} ${
                  NeedsActionFilterLength.length > 0
                    ? `(${NeedsActionFilterLength.length})`
                    : ""
                }
             `}
              >
                <NeedsActionFilters
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  resetForm={resetForm}
                  name={name}
                />
              </Tab>
            )}

            {id === "invoice" ||
            id === "user" ||
            id === "credit" ||
            id === "client" ||
            id === "letter" ||
            id === "check" ? (
              ""
            ) : (
              <Tab
                // title="Customer Stage"

                title={`${intl.formatMessage({
                  id: "customer_stage",
                })} ${
                  CustomerStageFilterLength.length > 0
                    ? `(${CustomerStageFilterLength.length})`
                    : ""
                }`}
              >
                <CustomerStageFilters
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  resetForm={resetForm}
                />
              </Tab>
            )}
          </Tabs>
        </form>
      )}
      returnFocus
      autoFocus
    >
      <div style={{ position: "relative" }}>
        {overAllLength > 0 ? (
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "end",
              top: "-15%",
              right: "-10%",
            }}
          >
            <Notification count={overAllLength > 0 ? overAllLength : ""} />
          </div>
        ) : (
          <></>
        )}

        <div style={{ width: "100%" }}>
          {" "}
          <IconButton size={SIZE.mini} kind={KIND.quaternary} fullWidth={true}>
            <AllFiltersIcon />
            {label}
          </IconButton>
        </div>
      </div>
    </StatefulPopover>
  );
};

export default AllFilters;
