import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useContext,
  createRef,
  useCallback,
} from "react";
import { useCallbackRef } from "use-callback-ref";
import { TextButton, KIND } from "../../components/TextButton";
// import { ModalButton } from "baseui/modal";
import { LabelLarge, ParagraphLarge, ParagraphMedium } from "baseui/typography";
// import * as ModalButton from "../TextButton";
import workflowModalPic from "../../assets/img/workqueue.png";
import relManagerPic from "../../assets/img/rel-manager.png";
import unSuccessCallPic from "../../assets/img/workqueue.png";
import workQueuePic from "../../assets/img/workqueue.png";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import warningImage from "../../assets/img/warning.png";
import { setInvoiceFilter } from "../../redux/invoiceSummary/action";
import {
  setCustomerSummaryFilter,
  setTableHeaderOrder,
  setTableWidth,
} from "../../redux/customerSummary/action";
import { useSelector } from "react-redux";
import CardMerge from "../../components/Card";
import PaginationWraper from "../../components/Pagination/Pagination";
import { ModalContext } from "../../providers/ModalProvider";
import { useDispatch } from "react-redux";
import {
  getCustomerSummaryGridStructure,
  updateTableCustomerSummaryDraftState,
  setCustomerSummaryTableView,
  setGlobalFilter,
  setCustomerSummarySort,
  setCustomerSummaryPageSize,
  setCustomerSummaryPageIndex,
  setCustomerSummaryGridAndView,
  setNeedsActionFilter,
  setCustomerStageFilter,
  setCustomerPinnedView,
} from "../../redux/customerSummary/action";
import CustomerAction from "../CustomerSummaryNew";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import setTableGridStructure from "../../utils/setTableGridStructure";
import { useDebouncedCallback } from "use-debounce";
import { TextBox, SIZE } from "../../components/TextBox";
import { useForm, Controller } from "react-hook-form";
import { Search } from "baseui/icon";
import AllFilters from "../../components/AllFilters/AllFilters";
import ColumnToogle from "../../components/ColumnToggle/ColumnToggle";
import { useIntl } from "react-intl";
import TableViewPicker from "../../components/TableViewPicker";
import Plus from "../../assets/img/svg/Upload/Plus";
import PlusOne from "../../assets/img/svg/Plus";
import CustomerSummaryPlus from "../../assets/img/svg/CustomerSummaryPlus";
import { SelectBox, inputOverrides } from "../../components/SelectBox";
import AssignWorkflowForm from "../../components/FormModal/AssignWorkflowForm";
import AssignRelationManagerForm from "../../components/FormModal/AssignRelationManagerForm";
import Loader from "../../components/Loader";
import CheckIn from "../../components/FormModal/CheckInForm";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  BULK_ASSIGN_WORKFLOW,
  BULK_ASSIGN_RM,
  BULK_CHECKIN,
  BULK_RM_WORKFLOW,
  BULK_PAUSE,
  BULK_STATUS,
  BULK_CALL_UNSUCESSFUL,
  RESCHEDULR_NEXT_ACTION,
  BULK_SKIP_TRACE,
} from "../../providers/RBACProvider/permissionList";
import { PLACEMENT } from "baseui/popover";
import { Input } from "baseui/input";
import PauseActionForm from "../../components/FormModal/PauseActionForm";
import StatusForm from "../../components/FormModal/StatusForm";
import CallUnsuccessBulkAssign from "../../components/FormModal/CallUnsuccessBulkAssign";
import BulkSkipTrace from "../../components/FormModal/BulkSkipTrace";
import NextActionBulkUpdate from "../../components/FormModal/NextActionBulkUpdate";
import WorkQueueForm from "../../components/FormModal/WorkQueueForm";
import voiceMailImg from "../../assets/img/VoicemailImg.png";
import VoiceMailForm from "../../components/FormModal/VoiceMailForm";
import { Icon, Typography } from "../../components_v2";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import useFormat from "../../hooks/useFormat";
import formatRecentTime from "../../utils_v2/FormatTimeInDays";
import AssignWorkflowStrategyForm from "../../components/FormModal/AssignWorkflowStrategyForm";
const TableBody = ({
  page,
  prepareRow,
  renderRowSubComponent,
  getTableBodyProps,
  visibleColumns,
}) => {
  const format = useFormat();
  return (
    <div className="customer__tabel__body" {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);
        const rowProps = row.getRowProps();

        return (
          <React.Fragment key={rowProps.key}>
            <div
              key={index}
              className={`table-main__body-row  --row_overflow${
                row.original.is_active ? "" : "row__disable"
              } ${row.isExpanded ? "expanded" : ""}`}
              {...row.getRowProps()}
            >
              {/* <Link to={`/customer/${index}`}> */}
              {row.cells.map((cell) => {
                return (
                  <div
                    className="table-main__body-cell"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
              {/* </Link> */}
            </div>
            {row.isExpanded &&
              renderRowSubComponent({
                row,
                rowProps,
                visibleColumns,
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const CustomerSummary = (props) => {
  let {
    currentOrganization,
    userPersonalization,
    referenceData,
    customCustomer,
  } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const expandedRowId = useRef(null);
  const setView = (props) => dispatch(setCustomerSummaryTableView(props));
  const [width, setWidth] = useState(0);
  const [isSearchPopover, setIsSearchPopover] = useState(false);
  let ref = useRef();
  let intl = useIntl();

  const dispatch = useDispatch();
  let reduxData = useSelector((state) => state.customerSummary.tableState);
  const currentFilteringData = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );
  useEffect(() => {
    if (ref.current) {
      // setWidth(ref.current?.offsetWidth);
      dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  let {
    viewName,
    gridAndViewLoaded,
    tableCurrentState,
    tableDraftView,
    tableOrder,
    gridStructure,
  } = useSelector((state) => state.customerSummary);

  // let globalFilter = { display_name: tableCurrentState.globalFilters };

  const [filterSearch, setFilterSearch] = useState(
    props?.queryFilter.display_name
  );

  const debounceFilter = useDebouncedCallback((value) => {
    gotoPage(0);
    dispatch(setCustomerSummaryPageIndex(0));
    props.setQueryFilter({ display_name: value });
    // dispatch(setGlobalFilter(value));
  }, 1000);

  useEffect(() => {
    const menu = "customer_summary";
    if (!viewName && !gridAndViewLoaded) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: getCustomerSummaryGridStructure,
        setViewAction: setCustomerSummaryTableView,
        setGridAndViewAction: setCustomerSummaryGridAndView,
        userPersonalization,
        dispatch,
        isSetGridAndView: true,
        customFields:
          customCustomer && Array.isArray(customCustomer) ? customCustomer : [],
      });
      // dispatch(setTableWidth(width));
    }
  }, []);

  // useEffect(() => {
  //   setWidth(ref.current.offsetWidth);
  // }, []);

  const columns = useMemo(() => {
    return reduxData.columns;
  }, [reduxData.columns]);

  const tabledata = useMemo(
    () =>
      props.isFetched &&
      !props.isError &&
      props.data &&
      props.data.data &&
      props.data.data.docs
        ? props.data.data.docs
        : [],
    [props.isFetched, props.data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    selectedFlatRows,
    setHiddenColumns,
    setAllFilters,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
    setSortBy,
    toggleRowExpanded,
    toggleRowSelected,
    toggleAllRowsSelected,
    initialState,
    isAllRowsSelected,
  } = useTable(
    {
      columns,
      initialState: {
        sortBy: tableCurrentState.sort ? tableCurrentState.sort : [],
        // pageIndex: tableCurrentState.pageIndex,
        pageIndex: tableCurrentState.pageIndex,

        pageSize: tableCurrentState.pageSize,
        // hiddenColumns: tableDraftView.table
        //   .filter((e) => e.is_hidden)
        //   .map((e) => e.col_id),
      },
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        props.isFetched &&
        !props.isError &&
        props.data &&
        props.data.data &&
        props.data.data.docs
          ? props.data.data.totalPages
          : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  // FILTERS STARTS

  // Customer Filter
  const filterReduxData = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );

  // Customer Stage Filter
  const customerStageFilters = useSelector(
    (s) => s.customerSummary.tableCurrentState.customer_stage
  );
  // Needs Action Filter

  const NeedsActionData = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );

  // FILTERS ENDS

  // TO FIND LENGTH STARTS
  const CustomFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );
  const NeedsActionFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );

  const CustomerStageFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.customer_stage
  );

  // TO FIND LENGTH ENDS

  // GRID STRUCTURE FOR CUSTOMER

  const gridStructureValues = useSelector(
    (s) => s.customerSummary.gridStructure.table
  );

  const pinnedView = useSelector((s) => s.customerSummary.pinnedView);

  // GRID STRUCTURE FOR CUSTOMER

  const currentColOrder = React.useRef();
  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    if (reduxData.sort) {
      let isDesc = reduxData.sort.startsWith("-");
      setSortBy([{ id: reduxData.sort.replace("-", ""), desc: isDesc }]);
    }
  }, [reduxData.sort]);

  // useEffect(() => {
  //   if (reduxData.filters) {
  //     setAllFilters(reduxData.filters);
  //   }
  // }, [reduxData.filters]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    setHiddenColumns(reduxData.hiddenColumns);
  }, [reduxData.hiddenColumns]);

  /** Update Table Structure Changes to Redux as Draft Table State **/

  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(updateTableCustomerSummaryDraftState(allColumns));
    }
  }, [isColumnResizing, sortBy, filters]);

  useEffect(() => {
    dispatch(updateTableCustomerSummaryDraftState(allColumns));
  }, [isColumnResizing, sortBy, filters, columns, allColumns, visibleColumns]);

  useEffect(() => {
    setColumnOrder(tableOrder);
  }, [tableOrder]);

  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return (
        <div className="table-main__row-expanded">
          <div colSpan={visibleColumns.length - 1}>Loading...</div>
        </div>
      );
    }

    // error handling here :)
    return (
      <>
        <div
          className="table-main__row-expanded"
          {...rowProps}
          key={`${rowProps.key}-expanded`}
        >
          <CardMerge />
        </div>
        <div className="table-main__row-expanded-bottom">
          <p>#Hospitality</p>
          <p>#foodSupplier</p>
          <p>#CriticalCustomer</p>
        </div>
      </>
    );
  }

  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(true);
    const [data] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={data}
        loading={loading}
      />
    );
  }

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  useEffect(() => {
    let currTableSort =
      sortBy && sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : sortBy[0].id
        : null;

    let currRdxSort =
      tableCurrentState.sort && tableCurrentState.sort[0]
        ? tableCurrentState.sort[0].desc
          ? "-" + tableCurrentState.sort[0].id
          : tableCurrentState.sort[0].id
        : null;

    if (currTableSort != currRdxSort) {
      gotoPage(0);
      dispatch(setCustomerSummarySort(sortBy));
    }
  }, [sortBy]);

  let selectedCustomer = [...(selectedFlatRows ? selectedFlatRows : [])].map(
    (i) => i.original._id
  );
  return (
    <>
      <div
        className="table-main"
        {...getTableProps()}
        style={{ overflowY: "hidden" }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div className="table-main-container" ref={ref}>
            <div className="table-main__head" style={{ position: "unset" }}>
              {headerGroups.map((headerGroup) => (
                <>
                  <div className="table-main__head-container">
                    {selectedFlatRows.length > 0 ? (
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          height: "50px",
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                          padding: "0px 8px",
                        }}
                      >
                        <RBACWrapper
                          role={BULK_ASSIGN_WORKFLOW}
                          type="PERMISSION"
                        >
                          <div
                            style={{
                              width: "110px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                isChildren: true,

                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <AssignWorkflowForm
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        customerRefetch={props.refetch}
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                image: workflowModalPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              {/* <div> */}
                              <i className="mx-icon-add" />
                              {/* <CustomerSummaryPlus /> */}
                              {/* </div> */}
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Workflow
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper
                          role={BULK_ASSIGN_RM || BULK_RM_WORKFLOW}
                          type={"PERMISSION"}
                        >
                          <div
                            style={{
                              width: "160px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },

                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <AssignRelationManagerForm
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        refetch={props.refetch}
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: relManagerPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Relation Manager
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper role={BULK_CHECKIN} type={"PERMISSION"}>
                          <div
                            style={{
                              width: "100px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <CheckIn
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: relManagerPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                CheckIn
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper role={BULK_STATUS} type="PERMISSION">
                          <div
                            style={{
                              width: "100px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <StatusForm
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: relManagerPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Status
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper role={BULK_PAUSE} type="PERMISSION">
                          <div
                            style={{
                              width: "120px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <PauseActionForm
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: relManagerPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Pause Action
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper
                          role={BULK_CALL_UNSUCESSFUL}
                          type={"PERMISSION"}
                        >
                          <div
                            style={{
                              width: "150px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <CallUnsuccessBulkAssign
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: unSuccessCallPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Call Unsuccessful
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper
                          role={RESCHEDULR_NEXT_ACTION}
                          type={"PERMISSION"}
                        >
                          <div
                            style={{
                              width: "150px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <NextActionBulkUpdate
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: unSuccessCallPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Reschedule
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                        <RBACWrapper role={BULK_SKIP_TRACE} type={"PERMISSION"}>
                          <div
                            style={{
                              width: "150px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <BulkSkipTrace
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: unSuccessCallPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <i className="mx-icon-add" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Skip Tracing
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>

                        <div
                          style={{
                            width: "150px",
                            height: "36px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              successCallback: () => {
                                // deleteTemplate.mutate(_id);
                              },
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <WorkQueueForm
                                      selectedCustomer={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                              image: workQueuePic,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <i className="mx-icon-add" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333860",
                                fontWeight: "450",
                              }}
                            >
                              Work Queue
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "150px",
                            height: "36px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              successCallback: () => {
                                // deleteTemplate.mutate(_id);
                              },
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <VoiceMailForm
                                      selectedCustomer={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                              image: voiceMailImg,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <i className="mx-icon-add" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333860",
                                fontWeight: "450",
                              }}
                            >
                              Voice Mail
                            </div>
                          </div>
                        </div>
                        <RBACWrapper
                          role={BULK_ASSIGN_WORKFLOW}
                          type="PERMISSION"
                        >
                          <div
                            style={{
                              width: "150px",
                              height: "36px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                isChildren: true,

                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <AssignWorkflowStrategyForm
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        customerRefetch={props.refetch}
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                image: workflowModalPic,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              {/* <div> */}
                              <i className="mx-icon-add" />
                              {/* <CustomerSummaryPlus /> */}
                              {/* </div> */}
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#333860",
                                  fontWeight: "450",
                                }}
                              >
                                Workflow Strategy
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                      </div>
                    ) : (
                      <>
                        <div
                          className="table-main__head-row"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <div
                            style={{
                              padding: "7px",
                              width: "260px",
                              // marginTop: "1%",
                            }}
                          >
                            {/* <StatefulPopover
                              overrides={{
                                Body: {
                                  style: ({ $theme }) => ({
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    width: "250px",
                                    // minHeight: "311px",
                                    background: "#ffffff",
                                  }),
                                },
                              }}
                              content={({ close }) =>
                                isSearchPopover ? (
                                  <div
                                    style={{
                                      background: "#fff",
                                      padding: "5px",
                                    }}
                                  >
                                    {referenceData["customer_search_type"].map(
                                      (fil) => {
                                        let values = {
                                          search_value: filterSearch,
                                          search_type: fil.id,
                                        };

                                        return (
                                          <div className="customer_search_type">
                                            <ParagraphMedium
                                              onClick={() => {
                                                dispatch(
                                                  setGlobalFilter(values)
                                                );

                                                close();
                                              }}
                                            >
                                              {fil.label}
                                            </ParagraphMedium>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                              returnFocus
                              autoFocus
                            > */}
                            <div>
                              <Input
                                clearable
                                autoComplete="off"
                                autoFill="off"
                                size={SIZE.mini}
                                placeholder={intl.formatMessage({
                                  id: "search_customer",
                                })}
                                // value={viewName}
                                endEnhancer={<Search size="18px" />}
                                overrides={{ ...inputOverrides }}
                                value={filterSearch}
                                onChange={(val) => {
                                  let value = val.target.value;
                                  debounceFilter(value);
                                  setFilterSearch(value);

                                  // dispatch(setGlobalFilter(value));
                                  // dispatch(setMainCustomerNameFilter(value));
                                }}
                              />
                            </div>
                            {/* </StatefulPopover> */}

                            {/* <StatefulPopover
                              content={({ close }) => (
                                <p>
                                  Popover will reposition itself to avoid being
                                  clipped!
                                  <br />
                                  <p> Try scrolling in this box...</p>
                                </p>
                              )}
                              returnFocus
                              autoFocus
                            >
                              <TextBox
                                autoComplete="off"
                                autoFill="off"
                                label={intl.formatMessage({
                                  id: "search_by_cus_name",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "search_by_cus_name",
                                })}
                               
                                onChange={(val) => {
                                  let value = val.target.value;
                                  setFilterSearch(value);
                                  debounceFilter(value);
                                  dispatch(setCustomerSummaryPageIndex(0));
                                  dispatch(setGlobalFilter(value));
                                  // dispatch(setMainCustomerNameFilter(value));
                                }}
                                size={SIZE.compact}
                                value={filterSearch}
                              />
                            </StatefulPopover> */}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              gap: "10px",
                            }}
                          >
                            <div style={{}}>
                              <TableViewPicker
                                allColumns={[]}
                                tableDraftView={tableDraftView}
                                viewName={viewName}
                                menu={"customer_summary"}
                                setView={setView}
                                currentFilteringData={currentFilteringData}
                                gridStructure={gridStructure}
                                colOrder={(data) =>
                                  dispatch(setTableHeaderOrder(data))
                                }
                                tableCurrentState={tableCurrentState}
                                pinnedView={pinnedView}
                                setPinnedView={setCustomerPinnedView}
                              />
                            </div>

                            <div style={{ width: "40px" }}>
                              <AllFilters
                                setQueryFilter={props.setQueryFilter}
                                setPageIndex={gotoPage}
                                filterReduxData={filterReduxData}
                                stageFilter={customerStageFilters}
                                NeedsAction={NeedsActionData}
                                name={"Custom"}
                                title={"custom"}
                                gridStructureValues={gridStructureValues}
                                mainFilter={(data) =>
                                  dispatch(setCustomerSummaryFilter(data))
                                }
                                needsActionFilter={setNeedsActionFilter}
                                customerStageFilter={setCustomerStageFilter}
                                pageIndex={setCustomerSummaryPageIndex}
                                mainFilterDataLength={CustomFilter}
                                needsActionFilterDataLength={NeedsActionFilter}
                                customerStageFilterDataLength={
                                  CustomerStageFilter
                                }
                                id={"customer"}
                              />
                            </div>
                            <ColumnToogle
                              allColumns={allColumns}
                              setColumnOrder={setColumnOrder}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className="table-main__head-row"
                      style={{ backgroundColor: "#F5F6FA" }}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <div
                          className="table-main__head-cell-wrapper"
                          {...column.getHeaderProps()}
                        >
                          <div className="table-main__head-cell">
                            {column.render("Header")}
                            <>
                              {column.canResize && (
                                <>
                                  <a
                                    className="table-main__head-cell-resizer"
                                    {...column.getResizerProps()}
                                  ></a>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
            {props.isLoading || !gridAndViewLoaded ? (
              <Loader />
            ) : tabledata && tabledata.length === 0 ? (
              <p
                style={{
                  paddingTop: "10px",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                No Records Found
              </p>
            ) : (
              <TableBodyMemo
                isColumnResizing={isColumnResizing}
                page={page}
                prepareRow={prepareRow}
                renderRowSubComponent={renderRowSubComponent}
                getTableBodyProps={getTableBodyProps}
                visibleColumns={visibleColumns}
              />
            )}

            <CustomerAction className="nested-ribbon" />
          </div>
        </div>
      </div>
      <PaginationWraper
        pageIndex={tableCurrentState.pageIndex}
        pageSize={tableCurrentState.pageSize}
        localKey={"customer_page_size"}
        pageCount={pageCount}
        gotoPage={(val) => {
          gotoPage(val);
          dispatch(setCustomerSummaryPageIndex(val));
        }}
        setPageSize={(val) => {
          setPageSize(val);
          dispatch(setCustomerSummaryPageSize(val));
        }}
      />
    </>
  );
};

export default CustomerSummary;
