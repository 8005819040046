import React, { useContext, useEffect, useState } from "react";
import "../../layouts/SideBar/sideBar.css";
import { Avatar } from "baseui/avatar";
import { KIND } from "baseui/button";
import { HeadingXSmall, ParagraphMedium } from "baseui/typography";
import { useDispatch, useSelector } from "react-redux";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import Sync from "../../assets/img/svg/Sync";
import { syncOrganization } from "../../services/organization";
import { IconButton } from "../../components/IconButton";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { resetCustomerOverviewState } from "../../redux/customerOverview/action";
import { setInitialAdhocState } from "../../redux/adhocUploadData/action";
import { initialValueState } from "../../redux/createWorkFlow/actions";
import { initialStateCredit } from "../../redux/CreditNote/action";
import { resetDiaryNoteReduxState } from "../../redux/customerDairyNote/action";
import { resetDisputeState } from "../../redux/customerOverview/dispute/action";
import { resetPaymentState } from "../../redux/customerOverview/payment/action";
import { getInitialCustomerSummary } from "../../redux/customerSummary/action";
import { initialInvoiceState } from "../../redux/invoiceOverview/action";
import { setInitialInvoiceSummary } from "../../redux/invoiceSummary/action";
import {
  getSettingData,
  refetchSettingData,
  setInitialSetting,
} from "../../redux/setting/actions";
import { setInitialUserState } from "../../redux/users/actions";
import { ProgressBar } from "baseui/progress-bar";
import { customerInvoiceOverview, getOrganization } from "../../services";
import {
  getSycnActive,
  setInitialSync,
} from "../../redux/organizationList/action";
import { ModalContext } from "../../providers/ModalProvider";
import warningImage from "../../assets/img/warning.png";
import { FormattedDate, FormattedTime, FormattedTimeParts } from "react-intl";
import moment from "moment";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import { ACCOUNTING_BOOKS } from "../../constants";
import { SYNC_ORGANIZATION } from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { setInitialCheckMt } from "../../redux/checkMaintenance/action";

const MappingOrgList = (props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const [isRefetch, setIsRefetch] = React.useState(0);
  const [isPercentage, setIsPercentage] = React.useState(0);
  const [isAllSync, setIsAllSync] = React.useState(
    props.sync && props.sync?.is_active
  );

  let maxyfiContextValue = useContext(MaxyfiContext);

  const { handleModal } = useContext(ModalContext);

  const {
    currentOrganization,
    referenceData,
    switchCurrentOrganization,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  let loading = true;
  const waitFor = (t = 500) => new Promise((res, rej) => setTimeout(res, t));

  const getOrganizationListData = async (props) => {
    let active = false;

    do {
      let orgDetails = await getOrganization({
        organization: props,
      });
      active = orgDetails.data.doc.sync.is_active;

      setIsPercentage(orgDetails.data.doc.sync.percentage);

      if (currentOrganization === props) {
        dispatch(getSycnActive(orgDetails.data.doc.sync.is_active));
      }
      // if (orgDetails.data.doc.sync.is_active) {
      setTimeout(() => {
        // waitFor(2000);
        setIsAllSync(orgDetails.data.doc.sync.is_active);
        // setIsRefetch(isRefetch + 1);
      }, 2000);
      // }

      if (!orgDetails.data.doc.sync.is_active) {
        refetch({
          setLoader: true,
          currentOrganization: currentOrganization,
        });
        // setIsRefetch(isRefetch + 1);
      }

      //   if (!orgDetails.data.doc.sync.is_active) {
      //     loading = false;
      //   } else {

      await waitFor(60000);
      //   }
    } while (active);
  };

  useEffect(() => {
    if (isAllSync) {
      getOrganizationListData(props.id);
    }
  }, [isRefetch]);

  const syncOrganizationData = useMutation(
    (data) =>
      syncOrganization({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: async (data, variables, context) => {
        refetch({
          setLoader: true,
          currentOrganization: currentOrganization,
        });
        getOrganizationListData(props.id);
      },
    }
  );

  const findCurrentOrgName = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  let date = utcTimstampToLocalDate(props?.sync?.sync_on);

  const switchOrganization = () => {
    let destination = location.pathname.split("/");

    destination =
      destination &&
      destination[1] &&
      !["credit-notes", "uploads"].includes(destination[1])
        ? `/${destination[1]}`
        : "/customers";

    switchCurrentOrganization({
      ...maxyfiContextValue,
      organizationId: props.id,
    });
    refetch({
      setLoader: true,
      currentOrganization: props.id,
    });
    if (props.accounting_book) {
      navigate(destination);
    } else {
      navigate(`/accounting/${props.id}`);
    }
    dispatch(resetCustomerOverviewState());
    dispatch(setInitialAdhocState());
    dispatch(initialValueState());
    dispatch(initialStateCredit());
    dispatch(resetDiaryNoteReduxState());
    dispatch(resetDisputeState());
    dispatch(resetPaymentState());
    dispatch(getInitialCustomerSummary());
    dispatch(initialInvoiceState());
    dispatch(setInitialInvoiceSummary());
    dispatch(setInitialSetting());
    dispatch(setInitialUserState());
    dispatch(setInitialSync());
    dispatch(setInitialCheckMt());
  };

  return (
    <div className="sync__wraper">
      <div
        className={
          findCurrentOrgName?.id == props.id
            ? "sidebar-organization-list__container--active"
            : "sidebar-organization-list__container"
        }
        onClick={() => {
          switchOrganization();

          // let destination = location.pathname.split("/");

          // destination =
          //   destination &&
          //   destination[1] &&
          //   !["credit-notes", "uploads"].includes(destination[1])
          //     ? `/${destination[1]}`
          //     : "/customers";

          // switchCurrentOrganization({
          //   ...maxyfiContextValue,
          //   organizationId: props.id,
          // });
          // refetch({ ...maxyfiContextValue, isOrgRefetch: false });
          // if (props.accounting_book) {
          //   navigate(destination);
          // } else {
          //   navigate(`/accounting/${currentOrganization}`);
          // }
          // dispatch(resetCustomerOverviewState());
          // dispatch(setInitialAdhocState());
          // dispatch(initialValueState());
          // dispatch(initialStateCredit());
          // dispatch(resetDiaryNoteReduxState());
          // dispatch(resetDisputeState());
          // dispatch(resetPaymentState());
          // dispatch(getInitialCustomerSummary());
          // dispatch(initialInvoiceState());
          // dispatch(setInitialInvoiceSummary());
          // dispatch(setInitialSetting());
          // dispatch(setInitialUserState());
          // dispatch(setInitialSync());
        }}
      >
        <div className="avather__wraper">
          <Avatar
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  backgroundColor: "#203239",
                  borderRadius: "5px",
                }),
              },
              Initials: {
                style: () => ({
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "700",
                }),
              },
            }}
            name={props.label}
            size="scale1000"
            src=""
          />
          <div style={{ flex: "1", width: "230px" }}>
            <ParagraphMedium $style={{ color: "#333860", flex: "1" }}>
              {props.label}
            </ParagraphMedium>
            {props.accounting_book == "CSV" ? null : (
              <div>
                <HeadingXSmall
                  $style={{ color: "#ADADAD", lineHeight: "17px" }}
                >
                  {props.is_token_expired ? (
                    "Disconnected"
                  ) : (
                    <>
                      Last Synced On:{" "}
                      {
                        <>
                          {moment(date).format(
                            currentDefaultFormatDetails.date_format
                          )}{" "}
                          <FormattedTimeParts value={date.toDate()}>
                            {(parts) => (
                              <>
                                {parts[0] && parts[0]?.value}
                                {parts[1] && parts[1]?.value}
                                {parts[2] && parts[2]?.value}
                              </>
                            )}
                          </FormattedTimeParts>
                        </>
                      }
                    </>
                  )}
                </HeadingXSmall>
              </div>
            )}
          </div>
        </div>
        {props.accounting_book == "CSV" ? null : (
          <div className="progress-bar__wraper">
            {isAllSync ? (
              <div className="progress-bar__label">
                <Sync />
                <HeadingXSmall $style={{ color: "#ffffff" }}>
                  {" "}
                  Syncing - {isPercentage} %
                </HeadingXSmall>
              </div>
            ) : (
              <></>
            )}

            {isAllSync ? (
              <ProgressBar
                value={isPercentage}
                overrides={{
                  BarProgress: {
                    style: ({ $theme, $value }) => {
                      return {
                        ...$theme.typography.font350,
                        position: "relative",
                        display: "inline-block",
                        verticalAlign: "middle",
                        background:
                          "#203239 linear-gradient(to bottom, #203239, #203239)",
                        color: "#ffffff",
                        position: "relative",
                        overflow: "hidden",
                        backgroundSize: "100%",
                        ":after": {
                          position: "absolute",
                          color: "#ffffff",
                          content: $value > 5 ? `"${$value}%"` : "",
                          left: "-35px",
                          right: "0px",
                          top: "0px",
                          bottom: "0px",
                          animation:
                            "progress-anim-1-after 0.5s infinite linear",
                          backgroundImage:
                            "linear-gradient(45deg, #ffffff 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, #ffffff 50%, #ffffff 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0))",
                          backgroundSize: "30px 30px",
                          opacity: "0.3",
                        },
                      };
                    },
                  },
                  Bar: {
                    style: ({ $theme }) => ({
                      height: $theme.sizing.scale600,
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                    }),
                  },

                  BarContainer: {
                    style: ({ $theme }) => ({
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginBottom: "0px",
                      marginTop: "0px",
                    }),
                  },
                }}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      <div className="sync__btn-contain">
        <RBACWrapper type="PERMISSION" role={SYNC_ORGANIZATION}>
          {props.accounting_book == "CSV" ? null : (
            <IconButton
              kind={KIND.tertiary}
              onClick={() => {
                if (props.id == currentOrganization && props.is_token_expired) {
                  dispatch(getSettingData(ACCOUNTING_BOOKS));
                  navigate("/settings");
                } else if (props.is_token_expired) {
                  switchOrganization();
                  dispatch(getSettingData(ACCOUNTING_BOOKS));
                  navigate("/settings");
                } else if (isAllSync) {
                  handleModal({
                    title:
                      "Sync is in progress! Would you like to initiate sync again?",
                    //   content:
                    //     "This will remove the uploaded logo. Are you sure?",
                    successCallback: () => {
                      syncOrganizationData.mutateAsync({
                        id: props.id,
                      });
                    },
                    formContent: () => {
                      return <></>;
                    },
                    cancelCallback: () => {},
                    isChildren: false,
                    image: warningImage,
                    buttonText: "Submit",
                    closeButtonText: "Cancel",
                    isCloseAble: false,
                  });
                } else {
                  syncOrganizationData.mutateAsync({
                    id: props.id,
                  });
                }
              }}
            >
              <Sync />
            </IconButton>
          )}
        </RBACWrapper>
      </div>
    </div>
  );
};

export default MappingOrgList;
