import moment from "moment";

const dynamicQueryFilter = (props) => {
  let {
    gridStructure = {},
    customerQueryFilter = "",
    timeZone = "",
    customerFilter = {},
    fieldName = "",
  } = props;

  let newFilter = {
    combinator: "and",
    rules: [],
    ...customerFilter,
  };

  const GridStructure = gridStructure.table?.reduce((prev, curr) => {
    if (curr.nested) {
      return [...prev, ...(curr?.nested ? curr?.nested : [])];
    }
    return [...prev, curr];
  }, []);

  const findDateObj = GridStructure?.filter((dq) => dq.type === "DATE").map(
    (dq) => dq.accessor
  );

  const dynamicDate = findDateObj?.map((dy) => dy + "_dynamic");

  if (fieldName && customerQueryFilter?.length >= 0) {
    newFilter.rules = [
      ...newFilter.rules,
      {
        operator: "contains",
        value: customerQueryFilter,
        id: "8fd3ed39-52ec-4e5e-986e-6829a8d0d047",
        field: fieldName,
        valueSource: "value",
      },
    ];
  }

  if (newFilter && newFilter.rules && newFilter.rules.length > 0) {
    newFilter.rules = newFilter.rules.map((fd) => {
      if (findDateObj && findDateObj?.includes(fd.field)) {
        let [dateFrom, dateTo] = fd.value.split(",");
        if (dateFrom && dateTo) {
          let newDateFrom = "";
          let newDateTo = "";

          if (dateFrom && moment(dateFrom).isValid()) {
            newDateFrom = moment
              .tz(dateFrom, timeZone)
              .startOf("day")
              .valueOf();
          }

          if (dateTo && moment(dateTo).isValid()) {
            newDateTo = moment.tz(dateTo, timeZone).endOf("day").valueOf();
          }

          return { ...fd, value: `${newDateFrom},${newDateTo}` };
        } else {
          if (dateFrom && moment(dateFrom).isValid()) {
            let newDateFrom = moment
              .tz(dateFrom, timeZone)
              .startOf("day")
              .valueOf();
            return { ...fd, value: newDateFrom };
          }
        }
      }

      if (dynamicDate && dynamicDate?.includes(fd.field)) {
        let numberRegex = /\d/gi;
        let symbelRegex = /[-+]/g;
        let [dateFrom, dateTo] = fd.value.split(",");

        if (dateFrom && dateTo) {
          let joinAlpa = dateFrom ? dateFrom?.match(/[A-Z]/gi)?.join("") : [];
          let daysFrom = dateFrom?.match(numberRegex);
          let joinFromDate = daysFrom ? daysFrom?.join("") : [];
          let actionFrom = dateFrom?.match(symbelRegex);
          let daysTo = dateTo?.match(numberRegex);
          let joinToDate = daysTo?.join("");
          let actionTo = dateTo?.match(symbelRegex);
          let replaceField = fd?.field?.replace("_dynamic", "");
          let convertDateFrom;

          if (joinFromDate) {
            convertDateFrom =
              actionFrom && actionFrom[0] === "-"
                ? moment
                    .tz(timeZone)
                    .subtract(joinFromDate, "day")
                    .startOf("day")
                    .valueOf()
                : moment
                    .tz(timeZone)
                    .add(joinFromDate, "day")
                    .startOf("day")
                    .valueOf();
          } else if (!joinFromDate && joinAlpa === "Today") {
            convertDateFrom = moment.tz(timeZone).startOf("day").valueOf();
          }

          let convertDateTo;

          if (joinToDate) {
            convertDateTo =
              actionTo && actionTo[0] === "-"
                ? moment
                    .tz(timeZone)
                    .subtract(joinToDate, "day")
                    .endOf("day")
                    .valueOf()
                : moment
                    .tz(timeZone)
                    .add(joinToDate, "day")
                    .endOf("day")
                    .valueOf();
          } else if (!joinToDate && joinAlpa === "Today") {
            convertDateTo = moment.tz(timeZone).startOf("day").valueOf();
          }

          return {
            ...fd,
            field: replaceField,
            value: `${convertDateFrom},${convertDateTo}`,
          };
        } else {
          let joinAlpa = dateFrom ? dateFrom?.match(/[A-Z]/gi)?.join("") : [];
          let daysFrom = dateFrom?.match(numberRegex);
          let joinFromDate = daysFrom ? daysFrom?.join("") : [];
          let actionFrom = dateFrom?.match(symbelRegex);
          let replaceField = fd?.field.replace("_dynamic", "");

          let convertDate;
          if (joinFromDate) {
            convertDate =
              actionFrom && actionFrom[0] === "-"
                ? moment
                    .tz(timeZone)
                    .subtract(joinFromDate, "day")
                    .startOf("day")
                    .valueOf()
                : moment
                    .tz(timeZone)
                    .add(joinFromDate, "day")
                    .startOf("day")
                    .valueOf();
          } else if (!joinFromDate && joinAlpa === "Today") {
            convertDate = moment.tz(timeZone).startOf("day").valueOf();
          }

          return { ...fd, field: replaceField, value: convertDate };
        }
      }
      return fd;
    });
  }
  return newFilter;
};

export default dynamicQueryFilter;
