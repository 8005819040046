import React, { useEffect, useState } from "react";
import "./assets/css/table.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { IntlProvider } from "react-intl";
import { Client as Styletron } from "styletron-engine-atomic";
import AppLocale from "./lang";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme } from "baseui";
import CombineRouter from "./router/combineRouters/CombineRouter";
import Login from "./layouts/LoginPage/Login";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./assets/scss/global.scss";
import colors from "./assets/scss/_variables.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./providers/AuthProvider";
import { MaxyfiProvider } from "./providers/MaxyfiProvider";
import { ModalProvider } from "./providers/ModalProvider";
import { RBACProvider } from "./providers/RBACProvider";
import { AxiosInterceptor } from "./providers/AxiosInterceptor";
import { ReactKeycloakProvider } from "@react-keycloak/web";
// import keycloak from "./Keycloak";
import keycloak from "./providers/KeyCloak";
import SwitchOrganizationProvider from "./providers/SwitchOrganizationModalProvider";
import DrawerCustom from "./containers/CustomerOverView/COTabContainers/Drawer";

import GlobalSearch from "./components/GlobalSearch/GlobalSearch";
import { ModalProvider_V2 } from "./providers/ModalProvider_V2";

import queryClient from "./providers/queryClient";
import { useIdleTimer } from "react-idle-timer";

const engine = new Styletron();

//TEMP AB
console.warn = () => {};

function App() {
  const defaultLocale = "en";
  let locale =
    navigator.language && navigator.language.split("-")[0]
      ? navigator.language.split("-")[0]
      : defaultLocale;
  let currentLocale = AppLocale[locale] || AppLocale[defaultLocale];
  console.warn = () => {};
  const PRIMARY_COLOR = colors.PRIMARY_COLOR;
  const SECONDARY_COLOR = colors.SECONDARY_COLOR;
  const SURFACE_COLOR = colors.SURFACE_COLOR;
  const BACKGROUND_COLOR = colors.BACKGROUND_COLOR;

  const customTheme = createTheme(
    { primary: PRIMARY_COLOR, primaryFontFamily: "Roboto" },
    {
      colors: {
        buttonIconPrimaryFill: BACKGROUND_COLOR,
        buttonIconPrimaryHover: PRIMARY_COLOR,
        buttonTertiaryHover: SURFACE_COLOR,
        buttonPrimaryfill: PRIMARY_COLOR,
        // buttonPrimaryHover: TEXT_BACKGROUND_COLOR,
        buttonPrimaryFill: PRIMARY_COLOR,
        buttonPrimaryHover: "#0348C0",
        buttonTertiaryFill: "transparent",
        buttonTertiaryHover: "#F0F3F8",
        buttonTertiaryText: "#323232",
        buttonTertiaryTextHover: PRIMARY_COLOR,
        buttonSecondaryFill: "#FFFFFF",
        buttonSecondaryHover: "#E2E2E2",
        tooltipBackground: SECONDARY_COLOR,
        tooltipText: "#FFFFFF",
        contentPrimary: "#333860",
        contentSecondary: "#495281",
        inputBorderColor: "#cdced9",
        inputBorder: "#CDCED9",
        customMxfi: "#000000",
        // buttonPraveen: "#000000",
      },
      borders: { radius: "5px", radius200: "5px", radius300: "8px" },
      typography: {
        ParagraphSmall: {
          fontSize: "13px",
          fontWeight: "normal",
        },
        ParagraphMedium: {
          fontSize: "13px",
          fontWeight: "400",
        },
        ParagraphLarge: {
          fontSize: "16px",
          fontWeight: "400",
        },
        HeadingXLarge: {
          fontSize: "20px",
          fontWeight: "700",
        },
        HeadingMedium: {
          fontSize: "34px",
          fontWeight: "900",
        },
        LabelSmall: {
          fontSize: "13px",
          fontWeight: "700",
        },
        LabelMedium: {
          fontSize: "13px",
          fontWeight: "700",
        },
        LabelLarge: {
          fontSize: "16px",
          fontWeight: "700",
        },
        LabelXSmall: {
          fontSize: "12px",
          fontWeight: "700",
        },
        HeadingXSmall: {
          fontSize: "12px",
          fontWeight: "400",
        },
      },
      // ParagraphSmall:{fontSize:"13px"}
    }
  );

  const { getRemainingTime } = useIdleTimer({
    onIdle: () => {
      keycloak.logout();
    },
    crossTab: true,
    timeout: 1800000,
    throttle: 30000,
    leaderElection: true,
    syncTimers: 15000,
    // timeout: 300000,
    // throttle: 60000,
    // leaderElection: true,
    // syncTimers: 60000,
  });

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ReactKeycloakProvider
          authClient={keycloak}
          LoadingComponent={() => {
            return <p>Loading</p>;
          }}
        >
          <IntlProvider
            locale={currentLocale.locale}
            messages={currentLocale.messages}
          >
            <StyletronProvider value={engine}>
              <BaseProvider theme={customTheme}>
                <QueryClientProvider client={queryClient}>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                  />
                  <AuthProvider>
                    <AxiosInterceptor>
                      <MaxyfiProvider>
                        <RBACProvider
                          permissions={[
                            "DOWNLOAD_VIEW_DATA",
                            "CREATE_VIEW",
                            "DELETE_VIEW",
                            "COPY_TEMPLATE",
                            "EDIT_TEMPLATE",
                            "VIEW_TEMPLATE",
                            "DELETE_TEMPLATE",
                            // "ADD_TEMPLATE",
                            "ADD_WORKFLOW",
                            "COPY_WORKFLOW",
                            "EDIT_WORKFLOW",
                            "VIEW_WORKFLOW",
                            "DELETE_WORKFLOW",
                            "DOWNLOAD_DIALER_LIST",
                          ]}
                        >
                          <ModalProvider>
                            <CombineRouter />
                            <SwitchOrganizationProvider />
                            <DrawerCustom />
                            <GlobalSearch />
                          </ModalProvider>
                        </RBACProvider>
                      </MaxyfiProvider>
                    </AxiosInterceptor>
                  </AuthProvider>
                  {/* <ReactQueryDevtools /> */}
                </QueryClientProvider>
              </BaseProvider>
            </StyletronProvider>
          </IntlProvider>
        </ReactKeycloakProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
