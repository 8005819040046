import React from "react";
function Invo(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3363 7.78516H8.1085C7.75033 7.78516 7.45312 8.0662 7.45312 8.4212C7.45312 8.7762 7.74271 9.05725 8.1085 9.05725H13.3363C13.6944 9.05725 13.9917 8.7762 13.9917 8.4212C13.9917 8.0662 13.6944 7.78516 13.3363 7.78516Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.35"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3363 11.1797H8.1085C7.75033 11.1797 7.45312 11.4607 7.45312 11.8157C7.45312 12.1707 7.74271 12.4518 8.1085 12.4518H13.3363C13.6944 12.4518 13.9917 12.1707 13.9917 11.8157C13.9917 11.4607 13.6944 11.1797 13.3363 11.1797Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.35"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3363 14.6484H8.1085C7.75033 14.6484 7.45312 14.9295 7.45312 15.2845C7.45312 15.6321 7.74271 15.9205 8.1085 15.9205H13.3363C13.6944 15.9205 13.9917 15.6395 13.9917 15.2845C13.984 14.9369 13.6944 14.6484 13.3363 14.6484Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.35"
        stroke-miterlimit="10"
      />
      <path
        d="M19.4395 3H6.5453C5.13548 3 4 4.11678 4 5.47762V5.48501V20.9498C4 21.4453 4.41152 21.8447 4.9221 21.8447C5.08975 21.8447 5.25741 21.8003 5.4022 21.719L7.81033 20.3137C7.88654 20.2694 7.97798 20.2694 8.05419 20.3137L10.729 21.8669C10.8662 21.9556 11.0339 22 11.2015 22C11.3692 22 11.5368 21.9556 11.6816 21.8743L14.3641 20.3137C14.4403 20.2694 14.5318 20.2694 14.608 20.3137L17.0008 21.719C17.2904 21.8817 17.641 21.8817 17.9306 21.719C18.2202 21.5636 18.3954 21.2678 18.3954 20.9424V10.2554H20.4454C21.3065 10.2554 22 9.57493 22 8.74659V5.48501C21.9924 4.11678 20.8569 3.0074 19.4395 3C19.4471 3 19.4471 3 19.4395 3ZM17.0694 20.2916L15.2633 19.2339C14.7756 18.9529 14.166 18.9529 13.6782 19.2339L11.1939 20.6835L8.71719 19.2339C8.22185 18.9455 7.60457 18.9455 7.10923 19.2413L5.30313 20.2989V5.48501C5.29551 4.82678 5.83658 4.27949 6.51482 4.26469C6.52244 4.26469 6.53006 4.26469 6.53768 4.26469H17.3362C17.1609 4.58272 17.0694 4.93772 17.0694 5.30012V20.2694V20.2916ZM20.674 8.74659C20.674 8.87972 20.5597 8.99066 20.4225 8.99066H18.3726V5.3223C18.3649 4.75282 18.8298 4.27949 19.4166 4.27209C19.4242 4.27209 19.4318 4.27209 19.4395 4.27209C20.1253 4.27209 20.674 4.81199 20.674 5.47022V8.74659Z"
        fill="#787878"
      />
      <path
        d="M19.4395 3H6.5453C5.13548 3 4 4.11678 4 5.47762V5.48501V20.9498C4 21.4453 4.41152 21.8447 4.9221 21.8447C5.08975 21.8447 5.25741 21.8003 5.4022 21.719L7.81033 20.3137C7.88654 20.2694 7.97798 20.2694 8.05419 20.3137L10.729 21.8669C10.8662 21.9556 11.0339 22 11.2015 22C11.3692 22 11.5368 21.9556 11.6816 21.8743L14.3641 20.3137C14.4403 20.2694 14.5318 20.2694 14.608 20.3137L17.0008 21.719C17.2904 21.8817 17.641 21.8817 17.9306 21.719C18.2202 21.5636 18.3954 21.2678 18.3954 20.9424V10.2554H20.4454C21.3065 10.2554 22 9.57493 22 8.74659V5.48501C21.9924 4.11678 20.8569 3.0074 19.4395 3ZM19.4395 3C19.4471 3 19.4471 3 19.4395 3ZM17.0694 20.2916L15.2633 19.2339C14.7756 18.9529 14.166 18.9529 13.6782 19.2339L11.1939 20.6835L8.71719 19.2339C8.22185 18.9455 7.60457 18.9455 7.10923 19.2413L5.30313 20.2989V5.48501C5.29551 4.82678 5.83658 4.27949 6.51482 4.26469C6.52244 4.26469 6.53006 4.26469 6.53768 4.26469H17.3362C17.1609 4.58272 17.0694 4.93772 17.0694 5.30012V20.2694V20.2916ZM20.674 8.74659C20.674 8.87972 20.5597 8.99066 20.4225 8.99066H18.3726V5.3223C18.3649 4.75282 18.8298 4.27949 19.4166 4.27209C19.4242 4.27209 19.4318 4.27209 19.4395 4.27209C20.1253 4.27209 20.674 4.81199 20.674 5.47022V8.74659Z"
        stroke="#787878"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
}

export default Invo;
