import React, { useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Typography from "./Typography";
import Icon from "./Icon";

import queryClient from "../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../constants";

const AGENT_INFO = "AGENT_INFO";
const CALLER_INST = "CALLER_INST";
const CLIENT_INST = "CLIENT_INST";
const callContent =
  "Greet and give a summary of the Outstanding Amount and duration overdue.\nUnderstand the reason why the payment is getting delayed.\nAppraise if needed the impact it can create like discontinued service.\nNegotiate for Promise To Pay by Date and if not arrange a follow-up Call or Meeting Request or capture the Dispute.\nNote: Enable and send Confirmation, Follow-up messages from the call action for better recovery.";
const AgentActionAlert = ({
  isCallAction = false,
  customCallInstruction = "",
}) => {
  const { customerId } = useParams();
  const [state, setState] = useState(null);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const agentInstruction = _.get(
    customerData,
    "data.doc.mini_miranda.agent_instruction",
    ""
  );

  return agentInstruction.length > 0 || isCallAction ? (
    <>
      <div className="co-action-alert">
        <div className="co-action-alert-header">
          <Typography>
            Alert:{" "}
            <span className="mx-p--regular">
              {" "}
              Read Instruction before taking Action
            </span>
          </Typography>
          <div className="co-action-alert-actions">
            {isCallAction ? (
              <div
                className="co-action-alert-btn"
                onClick={() => {
                  setState(CALLER_INST);

                  if (state === CALLER_INST) {
                    setState(null);
                  }
                }}
              >
                <Typography>Caller Instruction</Typography>
                <Icon
                  icon={state === CALLER_INST ? "chevron_up" : "chevron_down"}
                  color="#516BEB"
                />
              </div>
            ) : (
              <></>
            )}
            {agentInstruction && agentInstruction.length > 0 ? (
              <div
                className="co-action-alert-btn"
                onClick={() => {
                  setState(AGENT_INFO);

                  if (state === AGENT_INFO) {
                    setState(null);
                  }
                }}
              >
                <Typography>Agent Info</Typography>
                <Icon
                  icon={state === AGENT_INFO ? "chevron_up" : "chevron_down"}
                  color="#516BEB"
                />
              </div>
            ) : (
              <></>
            )}
            {_.get(customerData, "data.doc.agentInstruction", []).length > 0 ? (
              <div
                className="co-action-alert-btn"
                onClick={() => {
                  setState(CLIENT_INST);

                  if (state === CLIENT_INST) {
                    setState(null);
                  }
                }}
              >
                <Typography>Client Instruction</Typography>
                <Icon
                  icon={state === CLIENT_INST ? "chevron_up" : "chevron_down"}
                  color="#516BEB"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {state ? (
          <>
            <div className="co-action-alert-content">
              {state === AGENT_INFO ? agentInstruction : ""}
              {state === CALLER_INST &&
              !customCallInstruction &&
              !customCallInstruction.length > 0
                ? callContent
                : ""}
              {state === CALLER_INST &&
              customCallInstruction &&
              customCallInstruction.length > 0
                ? customCallInstruction
                : ""}
            </div>
            <div className="co_action_client_inst">
              {state === CLIENT_INST &&
                _.get(customerData, "data.doc.agentInstruction", []).length >
                  0 &&
                _.get(customerData, "data.doc.agentInstruction", []).map(
                  (i) => {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: i.instruction,
                        }}
                      />
                    );
                  }
                )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default AgentActionAlert;
