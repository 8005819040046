import React, { useEffect, useState, useContext } from "react";
import { SelectBox } from "../../SelectBox/SelectBox";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { SIZE } from "baseui/button";
import { TextButton, KIND } from "../../TextButton";
import MinMaxTabFilter from "../../TableTabFilter/MinMaxTabFilter/MinMaxTabFilter";
import TableHeaderAdder from "../../../assets/img/svg/TableHeaderAdder";
import DefaultTabFilter from "../../TableTabFilter/DefaultTabFilter/DefaultTabFilter";
import MultiSelectTabFilter from "../../TableTabFilter/MultiSelectTabFilter/MultiSelectTabFilter";
import DateTabFilter from "../../TableTabFilter/DateTabFilter/DateTabFilter";
import { useDispatch, useSelector } from "react-redux";
import setSelectValues from "../../../utils/setSelectValues";

import { IconButton } from "../../IconButton";
import getSelectValues from "../../../utils/getSelectValues";
import { filter } from "lodash";
import moment from "moment";
import {
  setCustomerSummaryFilter,
  setNeedsActionFilter,
  setCustomerStageFilter,
} from "../../../redux/customerSummary/action";
// import { QueryBuilderDnD } from "@react-querybuilder/dnd";
// import * as ReactDnD from "react-dnd";
// import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { QueryBuilder, defaultOperators } from "react-querybuilder";

const SelectedFilter = ({ type, rdName, ...props }) => {
  switch (type) {
    case "NUMBER":
      return <MinMaxTabFilter {...props} />;

    case "LOV":
      return <MultiSelectTabFilter rdName={rdName} {...props} />;

    case "CURRENCY":
      return <MinMaxTabFilter {...props} />;

    case "DATE":
      return <DateTabFilter {...props} />;

    case "STRING":
      return <DefaultTabFilter {...props} />;

    default:
      return null;
  }
};

const CustomFilters = (props) => {
  const intl = useIntl();
  const { referenceData, currentOrganization, appAccessRest } =
    useContext(MaxyfiContext);

  let tableGrid = props?.gridStructureValues;
  const [query, setQuery] = useState();

  const GridStructure = tableGrid?.reduce((prev, curr) => {
    if (curr.nested) {
      return [...prev, ...(curr?.nested ? curr?.nested : [])];
    }
    return [...prev, curr];
  }, []);

  const inputType = {
    STRING: "text",
    LOV: "select",
    DATE: "date",
    NUMBER: "number",
  };

  // let queryStrucher = GridStructure.map((e) => {
  //   let type = e.type && inputType[e.type] ? inputType[e.type] : e.type;
  //   return {
  //     ...(e.type == "CURRENCY"
  //       ? { name: e.accessor + ".value" }
  //       : { name: e.accessor }),
  //     label: intl.formatMessage({ id: e.literal_id }),

  //     ...(e.type == "LOV" && e.rd_name && referenceData[e.rd_name]
  //       ? {
  //           values: referenceData[e.rd_name].map((op) => {
  //             return {
  //               name: op.id,
  //               label: op.label,
  //             };
  //           }),
  //           valueEditorType: type,
  //         }
  //       : { inputType: type }),
  //   };
  // });

  let queryStrucher =
    GridStructure &&
    GridStructure?.reduce((prev, curr) => {
      if (!curr.filter_by) {
        return prev;
      }

      let type =
        curr.type && inputType[curr.type] ? inputType[curr.type] : curr.type;

      let queryData = {
        ...(curr.type == "CURRENCY"
          ? { name: curr.accessor + ".value" }
          : { name: curr.accessor }),
        label: intl.formatMessage({ id: curr.literal_id }),

        ...(curr.type == "DATE"
          ? {
              operators: defaultOperators.filter(
                (op) => op.name === "between" || op.name === "notBetween"
              ),
            }
          : {}),

        ...(curr.type == "LOV" && curr.rd_name && referenceData[curr.rd_name]
          ? {
              values: referenceData[curr.rd_name].map((op) => {
                return {
                  name: op.id,
                  label: op.label,
                };
              }),
              valueEditorType: type,
            }
          : { inputType: type }),
      };

      prev.push(queryData);

      if (curr.type == "DATE") {
        let creatObject = {
          name: curr.accessor + "_dynamic",
          label: intl.formatMessage({ id: curr.literal_id + "_dynamic" }),
          inputType: "text",
          operators: defaultOperators.filter(
            (op) => op.name === "between" || op.name === "notBetween"
          ),
          defaultValue: "{{Today+0}},{{Today+0}}",
          isDynamic: true,
        };
        prev.push(creatObject);
      }

      return prev;
    }, []);

  //

  //

  // const dispatch = useDispatch();

  // const orgRef = referenceData?.organizations?.find(
  //   ({ id }) => id === currentOrganization
  // );

  // const timeZone = orgRef.time_zone;
  // const defaultValues = useSelector((s) => s.filterTabsReducer.defaultData);

  // const gridStructureValues = useSelector(
  //   (s) => s.customerSummary.gridStructure.table
  // );

  // const filterReduxData = useSelector(
  //   (s) => s.customerSummary.tableCurrentState.formFilters
  // );

  // const selectVal = useSelector((s) => s.filterTabsReducer.selectedValue);

  let { control, watch, setValue, resetForm } = props;

  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
  //   {
  //     control,
  //     name: "filter",
  //   }
  // );

  // const watchFields = watch();

  // let selectingValue = watchFields.filter.map((i) => i.accessor);

  //

  // const removingCustomerName = GridStructure.filter(
  //   (i) => i.accessor !== "display_name"
  // );

  // let filters = [
  //   "display_name",
  //   "invoice_number",
  //   "is_2fa_setup",
  //   "is_active",
  //   "invoices",
  // ];
  // const filteredData = GridStructure.filter(
  //   ({ accessor }) => !filters.includes(accessor)
  // );

  const filteredData =
    GridStructure && GridStructure?.filter((i) => i.filter_by);

  let filetedList;

  if (appAccessRest.master_filter_type == "BUSINESS_UNIT") {
    // resetForm();
    filetedList = filteredData.filter((e) => e.accessor != "business_unit");
  } else if (
    appAccessRest.master_filter_type == "TEAM" ||
    appAccessRest.master_filter_type == "SELF"
  ) {
    // resetForm();
    filetedList = filteredData.filter((e) => e.accessor != "rel_manager");
  } else {
    filetedList = filteredData;
  }

  //

  // let filtered = filetedList?.filter(function (o) {
  //
  //   return selectingValue.indexOf(o.accessor) === -1;
  // });
  //
  // useEffect(() => {
  //   if (watchFields.filter.length === 0) {
  //     setValue("filter", [
  //       { id: [], type: "", name: "", cellType: "", rdName: "" },
  //     ]);
  //   }
  // }, [watchFields.filter]);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{ maxHeight: "300px", overflow: "scroll", paddingTop: "5px" }}
      >
        {/* <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}> */}
        <QueryBuilder
          controlElements={{
            addGroupAction: () => null,
            combinatorSelector: () => null,
            addRuleAction: ({ handleOnClick, ...rest }) => (
              <button {...rest} onClick={handleOnClick}>
                <FormattedMessage id="plus_add_filter">
                  plus_add_filter
                </FormattedMessage>
              </button>
            ),
          }}
          controlClassnames={{
            addRule: "mx-qb-add-rule",
            operators: "mx-qb-operators",
            removeRule: "mx-qb-remove-rule",
            fields: "mx-qb-fields",
            rule: "mx-qb-rule",
            value: "mx-qb-value",
            ruleGroup: "mx-qb-rule-group",
          }}
          fields={queryStrucher}
          query={props.value}
          onQueryChange={(q) => {
            setValue("filter", q);
          }}
        />
        {/* </QueryBuilderDnD> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: "10px",
          borderTop: "0.5px solid #CDCED9",
          paddingTop: "10px",
          marginTop: "10px",
        }}
      >
        <div style={{ width: "100px" }}>
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              resetForm();
            }}
          >
            <FormattedMessage id="clear_filters">
              clear_filters
            </FormattedMessage>
          </TextButton>
        </div>
        <div style={{ width: "100px" }}>
          <TextButton
            type="submit"
            kind={KIND.primary}
            size={SIZE.mini}
            fullWidth
          >
            <FormattedMessage id="apply">apply</FormattedMessage>
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default CustomFilters;
