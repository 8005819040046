import React from "react";

function DotSvg({ width = "22", height = "22" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0122 18.9749C10.6375 18.9749 10.3241 18.8465 10.072 18.5897C9.81989 18.3329 9.69385 18.0242 9.69385 17.6636C9.69385 17.311 9.81897 16.9983 10.0692 16.7256C10.3195 16.4529 10.6257 16.3165 10.988 16.3165C11.3627 16.3165 11.6761 16.4525 11.9282 16.7245C12.1803 16.9964 12.3063 17.3096 12.3063 17.6641C12.3063 18.0185 12.1816 18.3256 11.9321 18.5853C11.6826 18.845 11.376 18.9749 11.0122 18.9749ZM11.0122 12.3061C10.6375 12.3061 10.3241 12.181 10.072 11.9307C9.81989 11.6805 9.69385 11.3742 9.69385 11.0119C9.69385 10.6372 9.81897 10.3238 10.0692 10.0717C10.3195 9.81966 10.6257 9.69362 10.988 9.69362C11.3627 9.69362 11.6761 9.81836 11.9282 10.0678C12.1803 10.3173 12.3063 10.624 12.3063 10.9878C12.3063 11.3625 12.1816 11.6759 11.9321 11.928C11.6826 12.1801 11.376 12.3061 11.0122 12.3061ZM11.0122 5.6832C10.6375 5.6832 10.3241 5.55135 10.072 5.28764C9.81989 5.02393 9.69385 4.70692 9.69385 4.3366C9.69385 3.96628 9.81897 3.65126 10.0692 3.39154C10.3195 3.13181 10.6257 3.00195 10.988 3.00195C11.3627 3.00195 11.6761 3.13241 11.9282 3.39332C12.1803 3.65422 12.3063 3.96824 12.3063 4.33538C12.3063 4.70882 12.1816 5.02682 11.9321 5.28938C11.6826 5.55193 11.376 5.6832 11.0122 5.6832Z"
        fill="#787878"
      />
    </svg>
  );
}

export default DotSvg;
