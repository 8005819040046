import React from "react";
function TemplatesIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2015 8.1149C17.9141 6.93817 17.2458 5.90031 16.3118 5.1548C15.3705 4.4093 14.2209 4.00731 13.0281 4C11.8354 4 10.6785 4.40199 9.74447 5.14749C8.80321 5.893 8.13498 6.93086 7.84757 8.10759C6.4752 8.23915 5.2106 8.89695 4.31245 9.95674C3.4143 11.0092 2.94726 12.3833 3.00474 13.7793C3.06941 15.1753 3.65859 16.4982 4.65734 17.463C5.6489 18.4277 6.97816 18.9686 8.35054 18.9686H10.3552C10.5348 18.9686 10.7001 18.8955 10.8294 18.7712C10.9516 18.647 11.0234 18.4716 11.0234 18.2889C11.0234 18.1061 10.9516 17.938 10.8294 17.8065C10.7073 17.6822 10.5348 17.6091 10.3552 17.6091H8.35054C7.82602 17.6091 7.3015 17.5068 6.8129 17.3022C6.32431 17.0975 5.88601 16.7978 5.51238 16.4251C4.75793 15.665 4.334 14.6271 4.32682 13.5454C4.32682 12.4637 4.74356 11.4258 5.49082 10.6584C6.23808 9.89096 7.25839 9.45973 8.3218 9.45243C8.49424 9.46704 8.66669 9.41588 8.79602 9.30625C8.93254 9.19661 9.02595 9.04313 9.05469 8.87502C9.19121 7.90294 9.66543 7.01857 10.3983 6.37539C11.124 5.7322 12.0581 5.38138 13.0209 5.38138C13.9837 5.38138 14.9178 5.7322 15.6435 6.37539C16.3692 7.01857 16.8506 7.90294 16.9872 8.87502C17.0231 9.04313 17.1093 9.1893 17.2458 9.29894C17.3752 9.40126 17.5404 9.45973 17.7057 9.45973C18.7691 9.45973 19.7894 9.89096 20.5438 10.6511C21.2983 11.4185 21.715 12.4564 21.715 13.5381C21.715 14.6198 21.2911 15.6577 20.5438 16.4251C19.7966 17.1925 18.7691 17.6164 17.7057 17.6164H15.701C15.5214 17.6164 15.3561 17.6895 15.2268 17.8138C15.1046 17.938 15.0328 18.1134 15.0328 18.2962C15.0328 18.4789 15.1046 18.647 15.2268 18.7786C15.3489 18.9028 15.5214 18.9759 15.701 18.9759H17.7057C19.0709 18.9613 20.3786 18.4131 21.363 17.4556C22.3473 16.4909 22.9293 15.1826 22.994 13.7939C23.0587 12.4052 22.5988 11.0458 21.7078 9.99328C20.8097 8.92619 19.5595 8.26108 18.2015 8.1149Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.3"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8606 14.0056C15.99 14.1298 16.1552 14.1956 16.3277 14.1956C16.5001 14.1956 16.6726 14.1225 16.7947 13.9983C16.9168 13.874 16.9887 13.6986 16.9887 13.5232C16.9887 13.3478 16.924 13.1724 16.8019 13.0481L13.4608 9.64949C13.3386 9.52524 13.1662 9.45215 12.9865 9.45215C12.8069 9.45215 12.6416 9.52524 12.5123 9.64949L9.17119 13.0481C9.04904 13.1797 8.98438 13.3478 8.98438 13.5232C8.98438 13.6986 9.05623 13.874 9.17838 13.9983C9.30052 14.1225 9.47297 14.1956 9.64541 14.1956C9.81786 14.1956 9.9903 14.1298 10.1125 14.0056L12.3111 11.7691V20.3205C12.3111 20.5032 12.383 20.6713 12.5051 20.8028C12.6273 20.9271 12.7997 21.0002 12.9794 21.0002C13.159 21.0002 13.3242 20.9271 13.4536 20.8028C13.5757 20.6786 13.6476 20.5032 13.6476 20.3205V11.7618L15.8606 14.0056Z"
        fill="#787878"
        stroke="#787878"
        stroke-width="0.3"
        stroke-miterlimit="10"
      />
    </svg>
  );
}

export default TemplatesIcon;
