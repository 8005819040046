import React, { useContext, useEffect, useState } from "react";
import "../../layouts/SideBar/sideBar.css";
import { Avatar } from "baseui/avatar";
import Switch from "../../assets/img/svg/Switch";
import { StatefulPopover } from "baseui/popover";
import { Button } from "baseui/button";
import { LabelLarge } from "baseui/typography";
import { TextButton } from "../../components/TextButton";
import Plus from "../../assets/img/svg/Upload/Plus";
import { isModalOpen } from "../../redux/switchOrganization/action";
import { useDispatch } from "react-redux";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useLocation, useNavigate } from "react-router-dom";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { getOrganization } from "../../services";
import MappingOrgList from "./MappingOrgList";
import Sync from "../../assets/img/svg/Sync";
import { useSelector } from "react-redux";
import { ADD_ORGANIZATION } from "../../providers/RBACProvider/permissionList";

const OrgSwitchSync = () => {
  let navigate = useNavigate();
  const [isCount, setIsCount] = useState(true);

  let { isActive } = useSelector((e) => e.organizationListReducer);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const { currentOrganization, referenceData, restrictions, space } =
    maxyfiContextValue;

  useEffect(() => {
    if (space.org_count >= restrictions && restrictions.MULTORGACCESS) {
      setIsCount(false);
    }
  }, [space]);

  const findCurrentOrgName = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  return (
    <StatefulPopover
      overrides={{
        Body: {
          style: ({}) => ({
            backgroundColor: "#ffffff",
            marginLeft: "35px",
            borderRadius: "5px",
            top: "8%",
            boxShadow: "0px 4px 15px 10px #516beb30",
            zIndex: "2",
          }),
        },
        Inner: {
          style: ({}) => ({
            backgroundColor: "#ffffff",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "15px",
            paddingBottom: "20px",
          }),
        },
      }}
      content={({ close }) => (
        <div>
          <div>
            <LabelLarge padding="scale500" $style={{ textAlign: "center" }}>
              Switch Organization
            </LabelLarge>
          </div>
          <div className="sidebar-switch__organization-list">
            {referenceData &&
              referenceData?.organizations &&
              referenceData?.organizations?.map((res) => (
                <MappingOrgList {...res} />
              ))}
          </div>
          <div className="sidebar-switch__bottom-btn">
            <RBACWrapper type="PERMISSION" role={ADD_ORGANIZATION}>
              <RBACWrapper type="LICENSE" enabled={isCount} placement="bottom">
                <TextButton
                  fullWidth
                  onClick={() => {
                    navigate("/organization");
                  }}
                >
                  <Plus /> Add Organization
                </TextButton>
              </RBACWrapper>
            </RBACWrapper>
          </div>
        </div>
      )}
      accessibilityType={"tooltip"}
    >
      <Button
        type="button"
        overrides={{
          Root: {
            style: () => ({
              backgroundColor: "#ffffff00",
              position: "relative",
              paddingLeft: "0px",
              paddingRight: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
              borderRadius: "10px",
              ":hover": {
                backgroundColor: "#ffffff00",
              },
            }),
          },
        }}
      >
        <Avatar
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: "#F5F6FA",
              }),
            },
            Initials: {
              style: () => ({
                color: "#516beb",
                fontSize: "14px",
                fontWeight: "700",
              }),
            },
          }}
          name={findCurrentOrgName?.label}
          size="scale1000"
          src=""
        />
        {isActive ? (
          <div className="sidebar-sync__cointainer">
            <Sync />
          </div>
        ) : (
          <></>
        )}

        <div className="sidebar-switch__icon">
          <Switch />
        </div>
      </Button>
    </StatefulPopover>
  );
};

export default OrgSwitchSync;
