import React, { useState } from "react";

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_11527_100085)">
        <path
          d="M21.0833 20.1667H4.58333C3.85399 20.1667 3.15451 19.8769 2.63879 19.3612C2.12306 18.8455 1.83333 18.146 1.83333 17.4167V0.916667C1.83333 0.673552 1.73676 0.440394 1.56485 0.268485C1.39294 0.0965771 1.15978 0 0.916667 0C0.673552 0 0.440394 0.0965771 0.268485 0.268485C0.0965771 0.440394 0 0.673552 0 0.916667L0 17.4167C0.00145554 18.6318 0.484808 19.7967 1.34403 20.656C2.20326 21.5152 3.3682 21.9985 4.58333 22H21.0833C21.3264 22 21.5596 21.9034 21.7315 21.7315C21.9034 21.5596 22 21.3264 22 21.0833C22 20.8402 21.9034 20.6071 21.7315 20.4352C21.5596 20.2632 21.3264 20.1667 21.0833 20.1667Z"
          fill="#787878"
        />
        <path
          d="M5.49967 18.332C5.74279 18.332 5.97595 18.2355 6.14786 18.0635C6.31976 17.8916 6.41634 17.6585 6.41634 17.4154V10.9987C6.41634 10.7556 6.31976 10.5224 6.14786 10.3505C5.97595 10.1786 5.74279 10.082 5.49967 10.082C5.25656 10.082 5.0234 10.1786 4.85149 10.3505C4.67959 10.5224 4.58301 10.7556 4.58301 10.9987V17.4154C4.58301 17.6585 4.67959 17.8916 4.85149 18.0635C5.0234 18.2355 5.25656 18.332 5.49967 18.332Z"
          fill="#787878"
        />
        <path
          d="M9.16699 9.16667V17.4167C9.16699 17.6598 9.26357 17.8929 9.43548 18.0648C9.60739 18.2368 9.84054 18.3333 10.0837 18.3333C10.3268 18.3333 10.5599 18.2368 10.7318 18.0648C10.9037 17.8929 11.0003 17.6598 11.0003 17.4167V9.16667C11.0003 8.92355 10.9037 8.69039 10.7318 8.51849C10.5599 8.34658 10.3268 8.25 10.0837 8.25C9.84054 8.25 9.60739 8.34658 9.43548 8.51849C9.26357 8.69039 9.16699 8.92355 9.16699 9.16667Z"
          fill="#787878"
        />
        <path
          d="M13.75 11.9167V17.4167C13.75 17.6598 13.8466 17.8929 14.0185 18.0648C14.1904 18.2368 14.4236 18.3333 14.6667 18.3333C14.9098 18.3333 15.1429 18.2368 15.3148 18.0648C15.4868 17.8929 15.5833 17.6598 15.5833 17.4167V11.9167C15.5833 11.6736 15.4868 11.4404 15.3148 11.2685C15.1429 11.0966 14.9098 11 14.6667 11C14.4236 11 14.1904 11.0966 14.0185 11.2685C13.8466 11.4404 13.75 11.6736 13.75 11.9167Z"
          fill="#787878"
        />
        <path
          d="M18.333 8.2487V17.4154C18.333 17.6585 18.4296 17.8916 18.6015 18.0635C18.7734 18.2355 19.0066 18.332 19.2497 18.332C19.4928 18.332 19.7259 18.2355 19.8979 18.0635C20.0698 17.8916 20.1663 17.6585 20.1663 17.4154V8.2487C20.1663 8.00558 20.0698 7.77243 19.8979 7.60052C19.7259 7.42861 19.4928 7.33203 19.2497 7.33203C19.0066 7.33203 18.7734 7.42861 18.6015 7.60052C18.4296 7.77243 18.333 8.00558 18.333 8.2487Z"
          fill="#787878"
        />
        <path
          d="M5.50046 8.25045C5.74355 8.2504 5.97667 8.15379 6.14854 7.98187L9.43571 4.6947C9.61042 4.52826 9.84248 4.43542 10.0838 4.43542C10.3251 4.43542 10.5572 4.52826 10.7319 4.6947L12.7229 6.68571C13.2386 7.20125 13.9379 7.49087 14.6671 7.49087C15.3963 7.49087 16.0957 7.20125 16.6114 6.68571L21.7319 1.5652C21.8989 1.39232 21.9912 1.16077 21.9892 0.92042C21.9871 0.680073 21.8907 0.450162 21.7207 0.280204C21.5507 0.110247 21.3208 0.013842 21.0805 0.0117534C20.8401 0.00966485 20.6086 0.10206 20.4357 0.269038L15.3152 5.38862C15.1433 5.56047 14.9102 5.65701 14.6671 5.65701C14.4241 5.65701 14.1909 5.56047 14.019 5.38862L12.028 3.39854C11.5123 2.88299 10.813 2.59338 10.0838 2.59338C9.35459 2.59338 8.65524 2.88299 8.13954 3.39854L4.85237 6.68571C4.72421 6.8139 4.63694 6.97722 4.60159 7.15501C4.56623 7.3328 4.58439 7.51708 4.65375 7.68455C4.72312 7.85203 4.84058 7.99518 4.99129 8.09591C5.14199 8.19663 5.31918 8.25042 5.50046 8.25045Z"
          fill="#787878"
        />
      </g>
      <defs>
        <clipPath id="clip0_11527_100085">
          <rect width="22" height="22" fill="#787878" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SettingsIcon;
