import React from "react";
import { Icon, Typography } from "../../../../../components_v2";
import CheckBoxBaseweb from "../../../../../components/CheckBoxBaseweb";
import useFormat from "../../../../../hooks/useFormat";

const ManualGroupingCard = (props) => {
  const format = useFormat();
  const { isGrouping, setIsGrouping } = props;
  return (
    <div className="manual_grouping_card_container">
      <div className="manual_grouping_header_section">
        <div className="mg_title_wp">
          <Typography type="h3">
            {props?.consumer_name ? props?.consumer_name : ""}
          </Typography>
          <Icon icon="dot" size={6} color={"#D9D9D9"} />
          <Typography>
            {format.rd({
              id: props?.business_unit,
              name: "business_unit_list",
            })}
          </Typography>
        </div>
        <CheckBoxBaseweb
          checked={isGrouping === props._id}
          onChange={() => setIsGrouping(props?._id)}
        />
      </div>
      <div>
        <div className="dtl_wraper_container">
          <Icon icon="user_outLine" />
          <Typography>{props?.name?.toString()}</Typography>
        </div>
        <div className="dtl_wraper_container">
          <Icon icon="dob" />
          <Typography>
            {props?.dob?.map((e) => format.date({ value: e })).toString()}
          </Typography>
        </div>
        <div className="dtl_wraper_container">
          <Icon icon="call_outline" />
          <Typography>{props?.phone?.toString()}</Typography>
        </div>
        <div className="dtl_wraper_container">
          <Icon icon="email_outline" />
          <Typography>{props?.email?.toString()}</Typography>
        </div>
        <div className="dtl_wraper_container">
          <Icon icon="location_outline" />
          <Typography>
            {props?.address?.map((e) => Object.values(e)).toString()}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="dtl_wraper_container">
            <Icon icon="id_card" />
            <Typography>{props?.ssn?.toString()}</Typography>
          </div>
          {props.group_customer_count > 1 && (
            <Typography className="mx-chip--primery cp_tag">
              {`${props.group_customer_count} Consumer Grouped Records`}
            </Typography>
          )}
        </div>
        {props?.dl_number && props?.dl_number.length > 0 && (
          <div className="dtl_wraper_container">
            <Typography>DL #: {props?.dl_number?.toString()}</Typography>
          </div>
        )}
        {props?.dl_state && props?.dl_state.length > 0 && (
          <div className="dtl_wraper_container">
            <Typography>DL State: {props?.dl_state?.toString()}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualGroupingCard;
