import { Controller, useForm } from "react-hook-form";
import { TextButton } from "../../components/TextButton";
import ActionReminders from "../../components_v2/ActionReminders";
import { TextArea } from "../../components/TextArea";
import { SelectBox } from "../../components/SelectBox";
import AgentActionAlert from "../../components_v2/AgentActionAlert";
import ActionHeader from "./Actions/ActionHeader";
import getSelectValues from "../../utils/getSelectValues";
import {
  COMMUNICATION_TEMPLATE,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
} from "../../constants";
import { toast } from "react-toastify";
import {
  executeAdhocCustomerAction,
  executeCustomerAction,
  getCommunicationTemplateFinalContent,
  getCommunicationTemplateList,
} from "../../services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import _ from "lodash";
import getActionReminder from "../../utils_v2/getActionReminders";
import { REPLAY_SMS_DRAWER } from "../../redux/actions";
import { RDText, Typography } from "../../components_v2";
import moment from "moment";

const ReplaySMSAction = ({ resource = "CUSTOMER", closeDrawer }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const { currentOrganization, referenceData } = useContext(MaxyfiContext);

  const { data } = useSelector((state) => state.customerOverviewDrawer);
  const smsData = useSelector((state) => state.customerOverviewDrawer);

  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const templates = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-${currentOrganization}-${resource}-SMS`,
      {
        filters: { disable: false, type: "sms", tags: resource },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
      });
    }
  );

  let template_id = watch("template_id");

  // useEffect(() => {
  //   const getTemplateContent = async () => {
  //     let templateContent = await getCommunicationTemplateFinalContent({
  //       organization: currentOrganization,
  //       templateId: template_id[0].id,
  //       entity: resource,
  //       id: customerId,
  //     });

  //     if (templateContent.data && templateContent.data.doc) {
  //       let { value } = templateContent.data.doc;
  //       setValue("content", value);
  //     }
  //   };

  //   if (template_id && template_id[0] && template_id[0].id) {
  //     getTemplateContent();
  //   }
  // }, [template_id]);

  // const templateOptions = useMemo(() => {
  //   return templates &&
  //     templates.data &&
  //     templates.data.data &&
  //     templates.data.data.docs &&
  //     Array.isArray(templates.data.data.docs)
  //     ? templates.data.data.docs
  //         .filter(
  //           (itm) =>
  //             itm &&
  //             itm.tags &&
  //             Array.isArray(itm.tags) &&
  //             itm.tags.length === 1 &&
  //             itm.tags[0] === resource
  //         )
  //         .map((itm) => ({
  //           label: `${itm.name} (${
  //             itm.language
  //               ? itm.language.toLocaleUpperCase()
  //               : "en".toLocaleUpperCase()
  //           })`,
  //           id: itm._id,
  //         }))
  //     : [];
  // }, [templates.isFetching]);

  const contactOptions = useMemo(() => {
    const contacts = _.get(customer, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = phone
          .map(({ status, sms_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              sms_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.sms_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      },
      []
    );

    // if (data && data.isWorkflow) {
    //   if (data.recipientTypes && Array.isArray(data.recipientTypes)) {
    //     const selectedContacts = contacts.filter((e) =>
    //       data.recipientTypes.includes(e.designation)
    //     );

    //     setValue("recipients", selectedContacts);
    //   }

    //   setValue("content", data.content);
    // } else {
    //   const selectedContacts = contacts.filter((e) =>
    //     ["primary_contact"].includes(e.designation)
    //   );

    //   setValue("recipients", selectedContacts);
    // }

    if (smsData?.type === REPLAY_SMS_DRAWER) {
    }
    return contacts;
  }, [customer]);

  const executeActionMutation = useMutation(
    (formValues) =>
      !data || !data.isWorkflow
        ? executeAdhocCustomerAction({
            ...formValues,
            id: customerId,
            organization: currentOrganization,
            entity: resource,
          })
        : executeCustomerAction({
            ...formValues,
            id: customerId,
            organization: currentOrganization,
            entity: resource,
          }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`${CO_TIMELINE_}${customerId}`],
        });
        reset();
        closeDrawer();
      },
    }
  );

  const onSubmit = async (data) => {
    let recipients = [];
    if (data && data.recipients) {
      data &&
        data?.recipients?.map((e) => {
          recipients?.push({ id: e?.id, recipient_id: e?.recipient_id });
        });
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      action_type: "sms",
      content: data.content,
      ...(!data || !data.isWorkflow
        ? { template_id: getSelectValues(data.template_id) }
        : {}),
      recipients: recipients,
      ...getActionReminder(data, {
        is_consent_enabled: false,
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
        is_hold: false,
      }),
      mention_users: uniqueMentionedUsers,
      attachments: [],
    };

    await executeActionMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "500px" }}>
      <ActionHeader name="SMS" icon="sms" />

      <AgentActionAlert />
      <Controller
        name="recipients"
        control={control}
        rules={{ required: "Required" }}
        render={({ field }) => (
          <SelectBox
            size="mini"
            {...field}
            name={field.name}
            requiredAstric={true}
            noResultMessage={`${"Phone Number is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "recipients",
            })}
            placeholder={intl.formatMessage({
              id: "recipients",
            })}
            value={field.value}
            multi
            onChange={(e) => {
              field.onChange(e.value);
            }}
            options={contactOptions}
          />
        )}
      />
      {/* {!data || !data.isWorkflow ? (
        <Controller
          name="template_id"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size="mini"
              {...field}
              name={field.name}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "template_id",
              })}
              placeholder={intl.formatMessage({
                id: "template_id",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={templateOptions}
            />
          )}
        />
      ) : (
        <></>
      )} */}
      <Controller
        name="content"
        control={control}
        // rules={{ required: "Required" }}
        render={({ field }) => (
          <TextArea
            {...field}
            name={field.name}
            // requiredAstric={true}
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "sms_content",
            })}
            placeholder={intl.formatMessage({
              id: "sms_content",
            })}
            value={field.value}
          />
        )}
      />

      {_.get(smsData, "data.content.body", "") && (
        <div>
          <Typography type="p" className="text-secondary">
            {" "}
            On{" "}
            {moment(smsData?.data?.executed_on).format(
              "ddd, MMM DD, YYYY [at] hh:mm A"
            )}{" "}
            {" , "}{" "}
            {smsData?.data.executed_by ? (
              <>
                <RDText
                  rdName={"users"}
                  id={_.get(smsData, "data.executed_by", "")}
                />
              </>
            ) : (
              "Customer"
            )}{" "}
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "18px",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingLeft: "28px",
              width: "446px",
            }}
          >
            <Typography type="h4" className="">
              ----------------------------- Message
              -----------------------------{" "}
              <div
                style={{
                  width: "82px",
                  position: "relative",
                  top: "33px",
                  right: "60px",
                  flexDirection: "row",
                  height: "0px",
                  border: " 1px solid #CDCED9",
                  transform: "rotate(90deg)",
                }}
              ></div>
            </Typography>
            <Typography
              type="p"
              className="regular"
              style={{ textAlign: "left" }}
            >
              {" "}
              {_.get(smsData, "data.content.body", "")}
            </Typography>
          </div>
        </div>
      )}
      <div style={{ paddingTop: "100px" }}>
        <ActionReminders
          is_incrementor={false}
          is_comment={false}
          watch={watch}
          control={control}
          errors={errors}
          setValue={setValue}
          actionType="SMS"
          outcome="SMS"
          is_calendar={false}
          is_confirmation={false}
          is_consent_enabled={false}
          is_followup={false}
          is_hold={false}
        />
      </div>

      <div className="co-drawer-actions">
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          onClick={() => {
            closeDrawer();
          }}
          disabled={executeActionMutation.isLoading}
        >
          Close
        </TextButton>
        <TextButton
          size="mini"
          disabled={executeActionMutation.isLoading}
          isLoading={executeActionMutation.isLoading}
        >
          Send
        </TextButton>
      </div>
    </form>
  );
};

export default ReplaySMSAction;
