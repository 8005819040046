function Notifications() {
  return (
    <>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.26211 16.5099C1.04822 16.5099 0.868707 16.4373 0.723568 16.2922C0.578429 16.147 0.505859 15.9675 0.505859 15.7536C0.505859 15.555 0.578429 15.3793 0.723568 15.2266C0.868707 15.0738 1.04822 14.9974 1.26211 14.9974H2.47669V8.14531C2.47669 6.86198 2.85864 5.70087 3.62253 4.66198C4.38641 3.62309 5.39475 2.97378 6.64753 2.71406V2.0724C6.64753 1.70573 6.77739 1.40017 7.03711 1.15573C7.29683 0.911285 7.61767 0.789062 7.99961 0.789062C8.38155 0.789062 8.70239 0.911285 8.96211 1.15573C9.22183 1.40017 9.35169 1.70573 9.35169 2.0724V2.71406C10.6045 2.97378 11.6204 3.62309 12.3996 4.66198C13.1788 5.70087 13.5684 6.86198 13.5684 8.14531V14.9974H14.7371C14.951 14.9974 15.1343 15.0738 15.2871 15.2266C15.4399 15.3793 15.5163 15.555 15.5163 15.7536C15.5163 15.9675 15.4399 16.147 15.2871 16.2922C15.1343 16.4373 14.951 16.5099 14.7371 16.5099H1.26211ZM7.99961 19.2141C7.49544 19.2141 7.06003 19.0345 6.69336 18.6755C6.32669 18.3165 6.14336 17.8849 6.14336 17.3807H9.85586C9.85586 17.8849 9.67253 18.3165 9.30586 18.6755C8.93919 19.0345 8.50378 19.2141 7.99961 19.2141ZM3.98919 14.9974H12.0559V8.14531C12.0559 6.99948 11.6701 6.03316 10.8986 5.24635C10.127 4.45955 9.176 4.06615 8.04544 4.06615C6.89961 4.06615 5.93711 4.45955 5.15794 5.24635C4.37878 6.03316 3.98919 6.99948 3.98919 8.14531V14.9974Z"
          fill="#787878"
        />
      </svg>
    </>
  );
}

export default Notifications;
