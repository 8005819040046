// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;

export const getReferenceDataList = async (params = {}) => {
  return await axiosWithAuth
    .get(`${HOST}refrence_data`, {
      params: {
        ...params,
      },
    })
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};
