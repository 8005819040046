import React from "react";

const Misc = ({ color = "#adadad", size = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M15.7988 2C13.482 2 11.5977 3.88437 11.5977 6.20117C11.5977 8.51797 13.482 10.4023 15.7988 10.4023C18.1156 10.4023 20 8.51445 20 6.20117C20 3.88789 18.1156 2 15.7988 2ZM15.7988 9.2C14.143 9.2 12.8 7.85352 12.8 6.20117C12.8 4.54883 14.1465 3.20234 15.7988 3.20234C17.4547 3.20234 18.7977 4.54883 18.7977 6.20117C18.7977 7.85352 17.4547 9.2 15.7988 9.2ZM18.8012 15.7988C18.8012 17.4547 17.4547 18.7977 15.8023 18.7977C14.1465 18.7977 12.8035 17.4512 12.8035 15.7988C12.8035 14.143 14.15 12.8 15.8023 12.8V11.6012C13.4855 11.6012 11.6012 13.4855 11.6012 15.8023C11.6012 18.1191 13.4855 20 15.7988 20C18.1121 20 20 18.1156 20 15.7988H18.8012ZM9.2 6.20117C9.2 7.85703 7.85352 9.2 6.20117 9.2C4.54883 9.2 3.20234 7.85352 3.20234 6.20117C3.20234 4.54883 4.54883 3.20234 6.20117 3.20234V2C3.88437 2 2 3.88437 2 6.20117C2 8.51797 3.88437 10.4023 6.20117 10.4023C8.51797 10.4023 10.4023 8.51797 10.4023 6.20117H9.2ZM6.20117 11.6012C3.88437 11.6012 2 13.4855 2 15.7988C2 18.1121 3.88437 20 6.20117 20C8.51797 20 10.4023 18.1156 10.4023 15.7988C10.4023 13.482 8.51445 11.6012 6.20117 11.6012ZM6.20117 18.8012C4.54531 18.8012 3.20234 17.4547 3.20234 15.8023C3.20234 14.1465 4.54883 12.8035 6.20117 12.8035C7.85352 12.8035 9.2 14.15 9.2 15.8023C9.2 17.4547 7.85352 18.8012 6.20117 18.8012ZM9.37578 2.77695L6.20117 5.95156L5.42422 5.17461L4.57695 6.02188L5.77578 7.2207C5.8918 7.33672 6.04648 7.39648 6.20117 7.39648C6.35586 7.39648 6.50703 7.33672 6.62656 7.2207L10.223 3.62422L9.37578 2.77695ZM18.977 12.3746L15.8023 15.5492L15.0254 14.7723L14.1781 15.6195L15.377 16.8184C15.493 16.9344 15.6477 16.9941 15.8023 16.9941C15.957 16.9941 16.1082 16.9344 16.2277 16.8184L19.8242 13.2254L18.977 12.3746Z"
        fill={color}
      />
    </svg>
  );
};

export default Misc;
