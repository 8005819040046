import React, { useState, useEffect, useContext, useMemo } from "react";

import _ from "lodash";
import "react-quill/dist/quill.snow.css";

import { SelectBox } from "../../components/SelectBox";
import { Icon, Typography } from "../../components_v2";
import {
  ADD_CONTACT_DRAWER,
  EDIT_CONTACT,
  setDrawerState,
} from "../../redux/actions";
import { useDeleteCustomerContact } from "../../services/customerOverviewContacts";
import { useParams } from "react-router";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ModalContext } from "../../providers/ModalProvider";
import Warning from "../../assets/img/warning.png";
import { FormattedTime } from "react-intl";
import moment from "moment";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CONTACTS,
  EDIT_CONTACTS,
  DELETE_CONTACTS,
} from "../../providers/RBACProvider/permissionList";
import useFormat from "../../hooks/useFormat";
import { PLACEMENT, StatefulTooltip, TRIGGER_TYPE } from "baseui/tooltip";
import { parsePhoneNumberFromString } from "libphonenumber-js";
const ContactDetails = ({
  contact = {},
  setDrawer = () => {},
  mutateAsync = () => {},
}) => {
  const {
    phone = [],
    landline = [],
    address = [],
    email = [],
    custom_field = {},
  } = contact;

  const { customerId } = useParams();

  let { currentOrganization } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const format = useFormat();

  const { validPhone, validEmail, validLandline, validAddress } =
    useMemo(() => {
      let validPhone = phone?.filter((e) => e.status === "VALID");
      let validEmail = email?.filter((e) => e.status === "VALID");
      let validLandline = landline?.filter((e) => e.status === "VALID");
      let validAddress = address?.filter((e) => e.status === "VALID");
      return { validPhone, validEmail, validLandline, validAddress };
    }, [contact, phone, email, address, landline]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const commentPhoneExists = phone.some(
    (phone) => _.get(phone, "comment", "").trim() !== ""
  );

  const commentLandlineExists = landline.some(
    (landline) => _.get(landline, "comment", "").trim() !== ""
  );

  const commentAddressExists = address.some(
    (address) => _.get(address, "comment", "").trim() !== ""
  );

  const commentEmailExists = email.some(
    (email) => _.get(email, "comment", "").trim() !== ""
  );

  if (!contact || !contact._id) {
    return <></>;
  }
  const ValidPhoneNumberFormat = parsePhoneNumberFromString(
    `${validPhone[0]?.country_code}${validPhone[0]?.value}`
  );
  let formattedNumber =
    ValidPhoneNumberFormat && ValidPhoneNumberFormat?.formatInternational();

  const ValidLandlineFormat = parsePhoneNumberFromString(
    `${validLandline[0]?.country_code}${validLandline[0]?.value}`
  );
  let formattedLandline =
    ValidPhoneNumberFormat && ValidLandlineFormat?.formatInternational();

  function formatNumber(formattedNumber) {
    const firstSpaceIndex = formattedNumber.indexOf(" ");

    // Split the formatted number into two parts
    const firstPart = formattedNumber.substring(0, firstSpaceIndex + 1); // +1 to include the space
    const restPart = formattedNumber.substring(firstSpaceIndex + 1);

    // Replace spaces with hyphens in the rest part
    const restPartWithHyphens = restPart.replace(/ /g, "-");

    // Combine the first part and the modified rest part
    return firstPart + restPartWithHyphens;
  }

  //format SSN
  function formatSSN(ssn, fallback = "-") {
    if (!ssn || ssn.length !== 9 || isNaN(ssn)) {
      return fallback;
    }

    return `${ssn?.substring(0, 3)}-${ssn?.substring(3, 5)}-${ssn?.substring(
      5
    )}`;
  }

  const formattedSSN =
    custom_field && custom_field.ssn ? formatSSN(custom_field.ssn, "") : "-";

  return (
    <div className="co-contact-card_content">
      {validPhone && validPhone[0] ? (
        <div className="co-contact-card_element">
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
          >
            {formattedNumber ? formatNumber(formattedNumber) : "-"}{" "}
            {validPhone && validPhone.length > 1 ? (
              <Typography type="p" subType="regular" className="mx-chip">
                +{validPhone.length}
              </Typography>
            ) : (
              <></>
            )}
            {"-"}
          </Typography>

          {commentPhoneExists && (
            <Icon icon="contact_chat_icon" color="#ADADAD" />
          )}

          <Icon
            icon="phone_status"
            size={18}
            className={`${
              validPhone?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="sms_status"
            size={18}
            className={`${
              validPhone?.[0]?.sms_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="whatsapp_status"
            size={18}
            className={`${
              validPhone?.[0]?.whatsapp_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <></>
      )}
      {validLandline && validLandline[0] ? (
        <div className="co-contact-card_element">
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
          >
            {formattedLandline ? formatNumber(formattedLandline) : "-"}{" "}
            {_.get(validLandline, "", []) && validLandline.length > 1 ? (
              <Typography type="p" subType="regular" className="mx-chip">
                +{validLandline.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(landline[0], "comment", "") */}
            {commentLandlineExists && (
              <Icon icon="contact_chat_icon" color="#ADADAD" />
              // <StatefulTooltip
              //   triggerType={TRIGGER_TYPE.hover}
              //   placement={PLACEMENT.bottom}
              //   showArrow
              //   overrides={{
              //     Arrow: {
              //       style: ({ $theme }) => ({
              //         outline: `#16164B solid`,
              //         backgroundColor: "#16164B",
              //       }),
              //     },
              //     Inner: {
              //       style: ({ $theme }) => ({
              //         outline: `#16164B solid`,
              //         backgroundColor: "#16164B",
              //         maxWidth: "350px",
              //       }),
              //     },
              //   }}
              //   content={() => (
              //     <div style={{ backgroundColor: "#16164B" }}>
              //       <Typography
              //         type="p"
              //         subType="regular"
              //         style={{ color: "white" }}
              //       >
              //         Comments:{" "}
              //         {_.get(landline[0], "comment", "")
              //           ? _.get(landline[0], "comment", "")
              //               .replace(/(<([^>]+)>)/gi, "")
              //               .replace("&amp;", "")
              //           : ""}
              //       </Typography>
              //     </div>
              //   )}
              //   returnFocus
              //   autoFocus
              // >
              // </StatefulTooltip>
            )}{" "}
            {"-"}
          </Typography>

          <Icon
            icon="phone_status"
            size={18}
            className={`${
              validLandline?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <></>
      )}
      {validEmail && validEmail[0] ? (
        <div className="co-contact-card_element">
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
          >
            {validEmail?.[0]?.value}{" "}
            {validEmail.length > 1 ? (
              <Typography type="p" subType="regular" className="mx-chip">
                +{validEmail.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(email[0], "comment", "")  */}
            {commentEmailExists && (
              <Icon icon="contact_chat_icon" color="#ADADAD" />
              // <StatefulTooltip
              //   triggerType={TRIGGER_TYPE.hover}
              //   placement={PLACEMENT.bottom}
              //   showArrow
              //   overrides={{
              //     Arrow: {
              //       style: ({ $theme }) => ({
              //         outline: `#16164B solid`,
              //         backgroundColor: "#16164B",
              //       }),
              //     },
              //     Inner: {
              //       style: ({ $theme }) => ({
              //         outline: `#16164B solid`,
              //         backgroundColor: "#16164B",
              //         maxWidth: "350px",
              //       }),
              //     },
              //   }}
              //   content={() => (
              //     <div style={{ backgroundColor: "#16164B" }}>
              //       <Typography
              //         type="p"
              //         subType="regular"
              //         style={{ color: "white" }}
              //       >
              //         Comments:{" "}
              //         {_.get(email[0], "comment", "")
              //           ? _.get(email[0], "comment", "")
              //               .replace(/(<([^>]+)>)/gi, "")
              //               .replace("&amp;", "")
              //           : ""}
              //       </Typography>
              //     </div>
              //   )}
              //   returnFocus
              //   autoFocus
              // >

              // </StatefulTooltip>
            )}{" "}
            {"-"}
          </Typography>

          <Icon
            icon="email_status"
            size={18}
            className={`${
              validEmail[0].email_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <></>
      )}
      {validAddress && validAddress[0] ? (
        <div className="co-contact-card_element">
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
          >
            {validAddress?.[0]?.line_1} {validAddress?.[0]?.line_2} <br />
            {validAddress?.[0]?.city}{" "}
            {format.rd({
              id: _.get(validAddress, "[0]state", ""),
              name: "state_list",
            })}{" "}
            {_.get(validAddress, "[0]country", "")}{" "}
            {validAddress?.[0]?.zip_code}{" "}
            {validAddress.length > 1 ? (
              <Typography type="p" subType="regular" className="mx-chip">
                +{validAddress.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(address[0], "comment", "") */}
            {commentAddressExists && (
              <Icon icon="contact_chat_icon" color="#ADADAD" />
              // <StatefulTooltip
              //   triggerType={TRIGGER_TYPE.hover}
              //   placement={PLACEMENT.bottom}
              //   showArrow
              //   overrides={{
              //     Arrow: {
              //       style: ({ $theme }) => ({
              //         outline: `#16164B solid`,
              //         backgroundColor: "#16164B",
              //       }),
              //     },
              //     Inner: {
              //       style: ({ $theme }) => ({
              //         outline: `#16164B solid`,
              //         backgroundColor: "#16164B",
              //         maxWidth: "350px",
              //       }),
              //     },
              //   }}
              //   content={() => (
              //     <div style={{ backgroundColor: "#16164B" }}>
              //       <Typography
              //         type="p"
              //         subType="regular"
              //         style={{ color: "white" }}
              //       >
              //         Comments:{" "}
              //         {_.get(address[0], "comment", "")
              //           ? _.get(address[0], "comment", "")
              //               .replace(/(<([^>]+)>)/gi, "")
              //               .replace("&amp;", "")
              //           : ""}
              //       </Typography>
              //     </div>
              //   )}
              //   returnFocus
              //   autoFocus
              // >

              // </StatefulTooltip>
            )}{" "}
            {"-"}
          </Typography>

          <Icon
            icon="address_status"
            size={18}
            className={`${
              validAddress[0].address_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <></>
      )}
      <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
        {custom_field && custom_field.ssn ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            SSN:{" "}
            {formattedSSN ? formattedSSN : custom_field && custom_field?.ssn}
          </Typography>
        ) : (
          <></>
        )}
        {custom_field && custom_field.dob ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            DOB:{" "}
            {format.date({
              isOrg: true,
              value: custom_field && custom_field.dob ? custom_field.dob : null,
            })}
          </Typography>
        ) : (
          <></>
        )}
        {custom_field && custom_field.dl_state ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            DL State:{" "}
            {custom_field && custom_field.dl_state
              ? custom_field.dl_state
              : "-"}
          </Typography>
        ) : (
          <></>
        )}
        {custom_field && custom_field.dl_number ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            DL Number:{" "}
            {custom_field && custom_field.dl_number
              ? custom_field.dl_number
              : "-"}
          </Typography>
        ) : (
          <></>
        )}
      </div>

      <div className="add_contact_action_wraper">
        <div></div>
        {_.get(customerData, "data.doc.contacts", []).length > 0 ? (
          <div className="add_contact_icon_container">
            <RBACWrapper role={EDIT_CONTACTS} type="PERMISSION">
              <Icon
                icon="edit"
                size={16}
                color="#adadad"
                isPressable
                onClick={() => setDrawer(EDIT_CONTACT, contact)}
              />
            </RBACWrapper>
            <RBACWrapper role={DELETE_CONTACTS} type="PERMISSION">
              <Icon
                icon="delete"
                size={16}
                color="#adadad"
                isPressable
                onClick={() => {
                  handleModal({
                    title: "",
                    content: "Are you sure, you want to delete this Contact?",
                    successCallback: () => {
                      mutateAsync(
                        {
                          customerId: customerId,
                          organization: currentOrganization,
                          contactId: contact?._id,
                        },
                        {
                          onError: (error) => {
                            if (error.response) {
                              let { data } = error.response;
                              toast.error(data.message);
                            }
                          },
                          onSuccess: (data) => {},
                        }
                      );
                    },
                    formContent: () => {
                      return <></>;
                    },
                    cancelCallback: () => {},
                    isChildren: false,
                    image: Warning,
                    successButtonType: "error",
                    buttonText: "Delete",
                    closeButtonText: "Cancel",
                    isCloseAble: false,
                  });
                }}
              />
            </RBACWrapper>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const LocalTime = () => {
  const { customerId } = useParams();
  const [time, setTime] = useState(moment().format("hh:mm:ss a"));

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  useEffect(() => {
    const timerID = setInterval(
      () =>
        setTime(
          moment
            .tz(_.get(customerData, "data.doc.timezone", ""))
            .format("hh:mm:ss A")
        ),
      1000
    );

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <>
      <Icon icon="clock" size={18} />
      <Typography type="p" subType="regular">
        {time} <span className="text-secondary">(Local Time)</span>
      </Typography>
    </>
  );
};

export default function COContacts({ contacts = [], setDrawer = () => {} }) {
  const getContact = (contactsItem) => {
    // Filter contacts to get primary contacts
    const primaryContacts = contactsItem.filter(
      (contactfi) => contactfi?.designation === "primary_contact"
    );

    // Return the first primary contact if available, otherwise return the first contact in the list
    return primaryContacts?.length > 0 ? primaryContacts[0] : contacts[0] || {};
  };
  const [contact, setContact] = useState([]);

  useEffect(() => {
    if (getContact(contacts)) {
      setContact([
        {
          id: getContact(contacts)?._id,
          ...getContact(contacts),
        },
      ]);
    }
  }, [contacts, contacts.length]);

  const { mutateAsync } = useDeleteCustomerContact();

  return (
    <div className="co-contact-card_wrapper">
      <SelectBox
        size="mini"
        placeholder="No Contacts"
        clearable={false}
        value={contact}
        onChange={(e) => {
          setContact(e.value);
        }}
        options={
          contacts && Array.isArray(contacts)
            ? contacts.map((con) => {
                return {
                  label: `${con.first_name ? con.first_name : ""} ${
                    con.last_name ? con.last_name : ""
                  } ${con.relation ? `| ${con.relation}` : ""} | ${
                    con.designation && con.designation === "primary_contact"
                      ? "PRIMARY"
                      : con.designation &&
                        con.designation === "escalation_contact"
                      ? "ESCALATION"
                      : "SECONDARY"
                  }`,
                  id: con._id,
                  ...con,
                };
              })
            : []
        }
      />

      <div className="co-contact-card_container">
        <ContactDetails
          contact={contact && contact[0] ? contact[0] : {}}
          setDrawer={setDrawer}
          mutateAsync={mutateAsync}
        />
        <div className="co-contact-card_footer">
          <div className="co-contact-card-item">
            <LocalTime />
          </div>
          <RBACWrapper role={ADD_CONTACTS} type="PERMISSION">
            <div
              className="co-contact-card_button"
              onClick={() => setDrawer(ADD_CONTACT_DRAWER)}
            >
              <Typography type="p" subType="regular">
                + Add Contact
              </Typography>
            </div>
          </RBACWrapper>
        </div>
      </div>
    </div>
  );
}
