import React from "react";
function Invo(props) {
  return (
    // <svg
    //   width="25"
    //   height="25"
    //   viewBox="0 0 25 25"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M21.5911 2.95455C21.4774 2.15909 20.7956 1.47727 19.8865 1.47727C17.3865 1.36364 14.7729 1.25 12.5002 1.25C10.1138 1.25 7.72745 1.36364 5.11381 1.47727C4.20472 1.47727 3.5229 2.15909 3.40927 3.06818C3.182 6.02273 3.06836 9.20454 3.06836 12.5C3.06836 15.7955 3.182 19.0909 3.5229 22.1591C3.63654 23.0682 4.31836 23.75 5.22745 23.75H14.7729H14.8865H15.0002C16.2502 22.8409 17.3865 21.9318 18.4093 20.7955C19.432 19.7727 20.4547 18.6364 21.3638 17.3864C21.7047 14.7727 21.8184 12.2727 21.8184 9.77273C22.0456 7.27273 21.8184 5.11364 21.5911 2.95455ZM20.5684 16.4773C19.7729 16.3636 18.9775 16.25 17.9547 16.25C17.2729 16.25 16.4775 16.25 15.5684 16.3636C14.7729 16.4773 14.0911 17.1591 14.0911 17.9545C13.9774 18.8636 13.9775 19.6591 13.9775 20.2273C13.9775 21.1364 14.0911 21.9318 14.2047 22.7273H5.11381C4.65927 22.7273 4.432 22.3864 4.31836 22.0455C4.09109 18.9773 3.86381 15.7955 3.86381 12.5C3.86381 9.20454 3.97745 6.02273 4.31836 3.18182C4.31836 2.84091 4.65927 2.5 5.00018 2.5C7.72745 2.27273 10.2275 2.27273 12.5002 2.27273C14.7729 2.27273 17.2729 2.38636 19.8865 2.5C20.2275 2.5 20.5684 2.84091 20.5684 3.18182C20.7956 5.34091 20.9093 7.5 20.9093 9.54545C21.0229 11.7045 20.7956 14.0909 20.5684 16.4773Z"
    //     fill="#787878"
    //     stroke="#787878"
    //     stroke-width="0.2"
    //     stroke-miterlimit="10"
    //   />
    //   <path
    //     d="M12.9546 3.86377C10.2273 3.86377 7.95459 6.1365 7.95459 8.86377C7.95459 11.591 10.2273 13.8638 12.9546 13.8638C15.6819 13.8638 17.9546 11.591 17.9546 8.86377C17.9546 6.1365 15.6819 3.86377 12.9546 3.86377ZM12.9546 12.7274C10.7955 12.7274 8.97732 11.0229 8.97732 8.75013C8.97732 6.47741 10.7955 4.8865 12.9546 4.8865C15.1137 4.8865 16.9319 6.59104 16.9319 8.86377C16.9319 11.1365 15.1137 12.7274 12.9546 12.7274Z"
    //     fill="#787878"
    //   />
    //   <g clip-path="url(#clip0_6630_80093)">
    //     <path
    //       d="M13.8584 8.26314C13.6354 8.13992 13.3984 8.04605 13.1637 7.94865C13.0276 7.89232 12.8973 7.8266 12.7823 7.73507C12.5558 7.55436 12.5993 7.26099 12.8645 7.14481C12.9396 7.11195 13.0182 7.10139 13.098 7.0967C13.4054 7.08027 13.6976 7.1366 13.9758 7.27037C14.1142 7.33726 14.16 7.31614 14.2069 7.1718C14.2562 7.01925 14.2973 6.86435 14.3431 6.71062C14.3736 6.60736 14.336 6.53929 14.2386 6.49587C14.0602 6.41725 13.8772 6.36092 13.6847 6.33041C13.4336 6.29169 13.4336 6.29051 13.4324 6.03822C13.4313 5.68265 13.4313 5.68265 13.0745 5.68265C13.0229 5.68265 12.9713 5.68148 12.9196 5.68265C12.753 5.68734 12.7248 5.71668 12.7201 5.88449C12.7178 5.95959 12.7201 6.0347 12.719 6.11097C12.7178 6.33393 12.7166 6.33041 12.503 6.40786C11.9867 6.59562 11.6675 6.94767 11.6335 7.51094C11.603 8.00967 11.8635 8.34646 12.273 8.59172C12.5253 8.74309 12.8046 8.83228 13.0722 8.9508C13.1766 8.99657 13.2764 9.04937 13.3632 9.12213C13.6202 9.33453 13.5733 9.68775 13.2681 9.82153C13.105 9.89311 12.9325 9.91071 12.7553 9.88841C12.4819 9.85438 12.2202 9.7828 11.9738 9.65489C11.8295 9.57979 11.7872 9.59974 11.7379 9.75581C11.6957 9.89076 11.6581 10.0269 11.6206 10.163C11.5701 10.3461 11.5889 10.3895 11.7637 10.4752C11.9867 10.5831 12.2249 10.6383 12.4678 10.677C12.6579 10.7075 12.6638 10.7157 12.6662 10.9129C12.6673 11.0021 12.6673 11.0924 12.6685 11.1816C12.6697 11.2942 12.7237 11.36 12.8398 11.3623C12.9713 11.3647 13.1039 11.3647 13.2353 11.3611C13.3432 11.3588 13.3984 11.3001 13.3984 11.191C13.3984 11.0689 13.4043 10.9457 13.3996 10.8237C13.3937 10.6993 13.4477 10.6359 13.5674 10.6031C13.8432 10.528 14.0779 10.3801 14.2586 10.1595C14.7608 9.54928 14.5695 8.65626 13.8584 8.26314Z"
    //       fill="#787878"
    //     />
    //   </g>
    //   <path
    //     d="M10.9576 19.3184H6.0875C5.86317 19.3184 5.68164 19.5725 5.68164 19.8865C5.68164 20.2006 5.86317 20.4547 6.0875 20.4547H10.9576C11.1819 20.4547 11.3635 20.2006 11.3635 19.8865C11.3634 19.5725 11.1819 19.3184 10.9576 19.3184Z"
    //     fill="#787878"
    //   />
    //   <path
    //     d="M10.9576 19.3184H6.0875C5.86317 19.3184 5.68164 19.5725 5.68164 19.8865C5.68164 20.2006 5.86317 20.4547 6.0875 20.4547H10.9576C11.1819 20.4547 11.3635 20.2006 11.3635 19.8865C11.3634 19.5725 11.1819 19.3184 10.9576 19.3184Z"
    //     stroke="#787878"
    //   />
    //   <path
    //     d="M12.2157 17.0454H5.96574C5.80871 17.0454 5.68164 17.2995 5.68164 17.6136C5.68164 17.9276 5.80871 18.1818 5.96574 18.1818H12.2157C12.3728 18.1818 12.4998 17.9276 12.4998 17.6136C12.4998 17.2995 12.3727 17.0454 12.2157 17.0454Z"
    //     fill="#787878"
    //   />
    //   <path
    //     d="M12.2157 17.0454H5.96574C5.80871 17.0454 5.68164 17.2995 5.68164 17.6136C5.68164 17.9276 5.80871 18.1818 5.96574 18.1818H12.2157C12.3728 18.1818 12.4998 17.9276 12.4998 17.6136C12.4998 17.2995 12.3727 17.0454 12.2157 17.0454Z"
    //     stroke="#787878"
    //   />
    //   <defs>
    //     <clipPath id="clip0_6630_80093">
    //       <rect
    //         width="5.68182"
    //         height="5.68182"
    //         fill="white"
    //         transform="translate(10.2275 5.68213)"
    //       />
    //     </clipPath>
    //   </defs>
    // </svg>

    <svg
      width="25"
      height="25"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6749_81011)">
        <path
          d="M14.2992 10.8998C14.4992 10.8998 14.5992 10.7998 14.5992 10.5998C14.5992 10.3998 14.4992 10.2998 14.2992 10.2998H6.49922C6.29922 10.2998 6.19922 10.3998 6.19922 10.5998C6.19922 10.7998 6.29922 10.8998 6.49922 10.8998H14.2992Z"
          fill="#787878"
          stroke="#787878"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M6.60004 14.1996H11.9C12.1 14.1996 12.2 14.0996 12.2 13.8996C12.2 13.6996 12 13.5996 11.9 13.5996H6.60004C6.40004 13.5996 6.30004 13.6996 6.30004 13.8996C6.20004 13.9996 6.40004 14.1996 6.60004 14.1996Z"
          fill="#787878"
          stroke="#787878"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M14.2992 7.8002C14.4992 7.8002 14.5992 7.7002 14.5992 7.5002C14.5992 7.3002 14.4992 7.2002 14.2992 7.2002H6.49922C6.29922 7.2002 6.19922 7.3002 6.19922 7.5002C6.19922 7.7002 6.29922 7.8002 6.49922 7.8002H14.2992Z"
          fill="#787878"
          stroke="#787878"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M16.9008 12.7002C14.3008 12.7002 12.3008 14.9002 12.3008 17.6002C12.3008 20.3002 14.4008 22.5002 16.9008 22.5002C19.4008 22.5002 21.5008 20.3002 21.5008 17.6002C21.5008 14.9002 19.4008 12.7002 16.9008 12.7002ZM16.9008 21.9002C14.7008 21.9002 12.9008 20.0002 12.9008 17.6002C12.9008 15.2002 14.7008 13.3002 16.9008 13.3002C19.1008 13.3002 20.9008 15.2002 20.9008 17.6002C20.9008 20.0002 19.1008 21.9002 16.9008 21.9002Z"
          fill="#787878"
          stroke="#787878"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M18 19.2996C18.2 18.9996 18.3 18.6996 18.2 18.2996C18.1 17.8996 17.9 17.5996 17.6 17.3996C17.4 17.2996 17.2 17.1996 17 17.0996H16.9C16.7 16.9996 16.6 16.8996 16.5 16.7996C16.3 16.6996 16.3 16.4996 16.3 16.2996C16.3 16.0996 16.4 15.8996 16.6 15.8996C16.7 15.7996 16.8 15.7996 16.9 15.7996C17.2 15.7996 17.6 15.7996 17.9 15.9996C17.9 15.8996 18 15.6996 18 15.5996V15.4996C17.8 15.3996 17.6 15.3996 17.5 15.2996C17.2 15.1996 17.1 15.1996 17.1 14.7996C17.1 14.6996 17.1 14.5996 17.1 14.5996C17.1 14.5996 17 14.5996 16.9 14.5996C16.9 14.5996 16.9 14.5996 16.8 14.5996H16.7C16.7 14.6996 16.7 14.6996 16.7 14.7996V14.8996C16.7 15.1996 16.7 15.2996 16.4 15.3996C15.9 15.5996 15.6 15.8996 15.6 16.4996C15.6 16.8996 15.8 17.2996 16.2 17.4996C16.4 17.5996 16.6 17.6996 16.7 17.7996C16.8 17.7996 16.9 17.8996 17 17.8996C17.1 17.9996 17.3 17.9996 17.3 18.0996C17.5 18.1996 17.6 18.4996 17.5 18.6996C17.5 18.8996 17.3 19.0996 17.1 19.1996C16.9 19.2996 16.7 19.2996 16.5 19.2996C16.2 19.2996 15.9 19.1996 15.6 18.9996C15.6 19.0996 15.5 19.2996 15.5 19.3996C15.5 19.4996 15.5 19.4996 15.5 19.4996C15.5 19.4996 15.5 19.4996 15.6 19.4996C15.8 19.5996 16.1 19.6996 16.3 19.6996C16.6 19.6996 16.6 19.7996 16.6 20.0996C16.6 20.1996 16.6 20.1996 16.6 20.1996V20.2996C16.7 20.2996 16.9 20.2996 17 20.2996C17 20.2996 17 20.1996 17 20.0996C17 19.9996 17 19.8996 17 19.8996C17 19.7996 17 19.5996 17.3 19.4996C17.6 19.6996 17.8 19.4996 18 19.2996Z"
          fill="#787878"
          stroke="#787878"
          stroke-width="0.25"
          stroke-miterlimit="10"
        />
        <path
          d="M15.1 2.5H6.1C4.7 2.5 3.5 3.7 3.5 5.1V18.8C3.5 20.3 4.7 21.4 6.1 21.4H9.5H10.3H11.3C11.5 21.4 11.6 21.3 11.6 21.1C11.6 20.9 11.5 20.8 11.3 20.8H9.5H6.1C5 20.8 4.2 19.9 4.2 18.8V5.1C4.2 4 5.1 3.1 6.1 3.1H15.2C16.3 3.1 17.1 4 17.1 5.1L17.2 9.4V11C17.2 11.2 17.3 11.3 17.5 11.3C17.7 11.3 17.8 11.2 17.8 11V9.9V9V5.1C17.6 3.7 16.5 2.5 15.1 2.5Z"
          fill="#787878"
          stroke="#787878"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6749_81011">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Invo;
