// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

// export const getAuthorizationUrl = async ({ provider }) => {
//   return await axios.get(HOST + "auth/login/" + provider);
// };

// export const getAccessTokenWithAuthCode = async ({ provider, query }) => {
//   return await axios.get(HOST + "auth/thirdPartyLogin/" + provider + query);
// };

export const uploadCustomerAdhocData = async ({
  organization = null,
  formData,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/upload`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadCustomerVoiceData = async ({
  organization = null,
  formData,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/voice`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putAdhocUploadAdditionalData = async ({
  organization = null,
  values,
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/me?action=adoc_config`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadEpicFileData = async ({
  organization = null,
  templateId,
  mapFieldId,
  formData,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/adhoc-template/${templateId}/mapping-file/${mapFieldId}`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getAdhocMappingData = async ({
  organization = null,
  uploadId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/adhoc-data/adhoc-fields`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getDisputeContent = async ({
  organization = null,
  customerId,
  disputeId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/disputes/${disputeId}/forward`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getNotesAdhocMappingData = async ({
  organization = null,
  type,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/adhoc-data/adhoc-fields`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        adhoc_upload_type: type,
      },
    }
  );
};

export const getAdhocTabelData = async ({
  organization = null,
  type,
  adhocId = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/adhoc-data/${adhocId}/first-row-data`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        adhoc_upload_type: type,
      },
    }
  );
};

export const getAdhocTableData = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/adhoc-data`, {
    headers: {
      appCode: APP_CODE,
    },
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};

export const deleteAdhocData = async ({ adhocId, organization = null }) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/adhoc-data/${adhocId?.adhoc_id}/delete-all`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadAdhocCustomerInvoiceData = async ({
  organization = null,
  data,
  uploadId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/${uploadId}/add-customer-invoice`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadPredefinedData = async ({
  organization = null,
  data,
  uploadId,
  temId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/${uploadId}/predefined-mapping/${temId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadAdhocNote = async ({
  organization = null,
  data,
  uploadId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/${uploadId}/notes`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadAdhocPayment = async ({
  organization = null,
  data,
  uploadId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/${uploadId}/check-payment`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadCustomerContact = async ({
  organization = null,
  data,
  uploadId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/adhoc-data/${uploadId}/check-customer-contact`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const confirmAdhocData = async ({
  organization = null,
  data,
  uploadId,
  action,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/adhoc-data/${uploadId}/add-customer-invoice`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        action,
      },
    }
  );
};

export const getAdhocTemplateData = async ({
  organization = null,
  templateType,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/adhoc-template/${templateType}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const downloadAdhocTemplateData = async ({
  organization = null,
  templateType,
}) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/adhoc-data/download-template`, {
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      download({ fileName: "template_file.csv", fileData: blob.data });
    });
};

export const downloadErrorFile = async ({ organization = null, uploadId }) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/adhoc-data/${uploadId}/download-error-file`, {
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      download({
        fileName: `error_file.${
          blob?.headers?.["content-type"]?.split("/")[1]
        }`,
        fileData: blob.data,
      });
    });
};

export const downloadSuccessFile = async ({
  organization = null,
  uploadId,
}) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/adhoc-data/${uploadId}/download-file`, {
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      download({
        fileName: `uploaded_file.${
          blob?.headers?.["content-type"]?.split("/")[1]
        }`,
        fileData: blob.data,
      });
    });
};

export const downloadClientFile = async ({ organization = null, uploadId }) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/adhoc-data/${uploadId}/download-file`, {
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob.data], { type: "text/csv;charset=utf-8" })
      );
      return url;
    });
};
